import React from 'react';
import { Button } from 'semantic-ui-react'

const DownloadLink = ({data, fileName, label, callback = ()=>{}}) => (
<a
  onClick={callback}
  href={`data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`}
  download={`${fileName}.json`}
>
  <Button>{label}</Button>
</a>
);

export default DownloadLink;
