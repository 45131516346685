import "./index.scss";
import React, {useState, useEffect} from "react";
import { Table, Input } from "semantic-ui-react";
import {unflatten} from 'flat';
import { useTranslation } from 'react-i18next';


const EmmissionVarFieldTable = ({ data, error, onchange, onblur}) => {
  const [rows, setRows] = useState({});
  const [rowOrder] = useState(["CO2", "CH4", "N2O", "SO2", "NOx", "PM25"]);
  const { t } = useTranslation();

  useEffect(()=>{
    let _rows = {}
    for(let key in data) {
      if(key.match("EmissionCoefficients.")){
        _rows[key.replace("EmissionCoefficients.", '')] = data[key];
      }
    }
    setRows(unflatten(_rows));
  },[data]);

  return rows && Object.keys(rows) && Object.keys(rows).length && (
    <Table className="emission-var-field-table" compact="very" unstackable collapsing celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell colSpan={6}>{t('advanced.emissionSection.powerUsage')}</Table.HeaderCell>
          <Table.HeaderCell>{t('advanced.emissionSection.gasUsage')}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          {
            rowOrder.map((_title, i)=><Table.HeaderCell key={_title}>{_title} {i ? '(g/MWh)' : '(kg/MWh)'}</Table.HeaderCell>)
          }
          <Table.HeaderCell>CO2 (kg/GJ)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          Object.keys(rows['GasEmissionsPerYear']).map((year, i) => (
            <Table.Row key={`row-${i}`}>
              <Table.Cell>{year}</Table.Cell>
                {rowOrder.map((_key) => (
                  <Table.Cell key={`row-${i}-${year}-${_key}`}>
                    <Input 
                      error={!!error}
                      type="number"
                      value={rows['PowerEmissionsPerYear'][year][_key]} 
                      onBlur={(e)=>{onblur(`EmissionCoefficients.PowerEmissionsPerYear.${year}.${_key}`, parseFloat(e.target.value))}}
                      onChange={(e)=>{onchange(`EmissionCoefficients.PowerEmissionsPerYear.${year}.${_key}`, e.target.value)}}
                    />  
                  </Table.Cell>
                ))}
                <Table.Cell key={`row-${i}-${year}`}>
                  <Input 
                    error={!!error}
                    type="number"
                    value={rows['GasEmissionsPerYear'][year]} 
                    onBlur={(e)=>{onblur(`EmissionCoefficients.GasEmissionsPerYear.${year}`, parseFloat(e.target.value))}}
                    onChange={(e)=>{onchange(`EmissionCoefficients.GasEmissionsPerYear.${year}`, e.target.value)}}
                  />  
                </Table.Cell>
            </Table.Row>
          ))
        }
      </Table.Body>
    </Table>
  );
}

export default EmmissionVarFieldTable;
