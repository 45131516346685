const live = "https://thermonet-calc.azurewebsites.net";
//const local = "https://thermonet-calc.azurewebsites.net";
//const live = "https://localhost:5001";
const local = "https://localhost:5001";
const API = window && window.location && window.location.host.match("localhost") ? local : live;

export const updateScenario = (key,data) => {
  return fetch(`${API}/scenario/${key}`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(res => res.json());
  }

export const getScenarioList = () => {
  return fetch(`${API}/scenario`)
  .then(res => res.json())
}

export const getScenario = (key) => {
  return fetch(`${API}/scenario/${key}`)
  .then(res => res.json())
}

export const getDefinitions = () => {
  return fetch(`${API}/explanation/scenario`)
  .then(res => res.json())
}

export const getSolutionDefinitions = () => {
  return fetch(`${API}/explanation/solution`)
  .then(res => res.json())
}

export const getSensitivityAnalysis = (key, path, scenario, factors = [-30,-20,-10,0,10,20,30]) => {
  return fetch(`${API}/sensitivity-analysis/${key}`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "SensitivityVariablePath": path,
      "Factors": factors,
      "Scenario": scenario
    })
  })
  .then(res => res.json());
}