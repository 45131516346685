import "./index.scss";
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { parseUnit } from '../../services/utils';

const AccumulatedBarChart = ({data})=>{
  const { i18n } = useTranslation();

  const _years = Object.keys(data);
  const _values = _years.reduce((acc,cur)=>{
    acc.push(data[cur]);
    return acc;
  },[]);
  const _data = {
    labels: _years,
    datasets: [
      {
        label: i18n.language === "dk" ? 'Akkumuleret balance' : 'Accumulated balance',
        data: _values,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  }
  
  const _options = {
    tooltips: {
      callbacks: {
        label: (item) => `${item.yLabel} ${parseUnit('Currency', i18n.language)}`,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            userCallback: (item) => `${item.toLocaleString()} ${parseUnit('Currency', i18n.language)}`,
            beginAtZero: true,
          },
        },
      ],
    },
  }
  
  return (
    <div className="accumulated-bar-chart">
      <Bar data={_data} options={_options} />
    </div>
  );
}

export default AccumulatedBarChart;