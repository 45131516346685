import React from 'react';
import { Icon, Popup } from 'semantic-ui-react'

const HelpText = ({title, body, key}) => (
  <Popup
    trigger={<Icon name='help' size='small' circular />}
    content={body}
    key={key}
    header={title}
    className="help-text"
  />
);

export default HelpText;
