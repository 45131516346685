import './index.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { parseDefProp,parseUnit } from '../../services/utils';

const EmissionsTable = ({data, def})=>{

  const { t, i18n } = useTranslation();
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;
  const types = ["CO2","CH4","N2O","SO2","NOx","PM25"];
  const cases = ["TotalEmissionsBefore","TotalEmissionsAfter","TotalReductionsPerYearOfOperation","TotalEmissionReductions","PricePerReduction"];
  
  return (
    <div className="emissions-table">
      <Table unstackable celled compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell key={`head`}></Table.HeaderCell>
            {
              cases.map((_case)=><Table.HeaderCell key={`head-${_case}`}>{t(`Emissions.${_case}`)}</Table.HeaderCell>)
            }
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            types.map((_type)=>{
              const rowProps = parseDefProp(def, "Emissions."+cases[0]+'.'+_type);
              const priceRowProps = parseDefProp(def, "Emissions.PricePerReduction."+_type);

              return (
                <Table.Row key={`body-${_type}`}>
                  <Table.Cell><strong>{(rowProps && rowProps.Title) ? rowProps.Title[languageCode] : _type}</strong></Table.Cell>
                  {
                    cases.map((_case,i)=>(
                        <Table.Cell textAlign="right" key={`body-${_type}-${_case}`}>{data[_case][_type] ? `${data[_case][_type].toLocaleString()} ${(_case === 'PricePerReduction' && priceRowProps) ? parseUnit("Currency/kg",i18n.language) : (rowProps && rowProps.PropertyUnit) ? parseUnit(rowProps.PropertyUnit,i18n.language) : ''}` : ""}</Table.Cell>
                    ))
                  }
                </Table.Row>
              );
            })
          }
        </Table.Body>
      </Table>
    </div>
  );
}

export default EmissionsTable;