import './index.scss';
import React, { useState, useEffect } from 'react';
import {flatten} from 'flatley';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react'
import KeyValues from "../../components/KeyValues";
import AccumulatedBarChart from "../../components/AccumulatedBarChart";
import SensitivityAnalysis from '../../components/SensitivityAnalysis';
import BusinessCasesTable from '../../components/BusinessCasesTable';
import OutputTable from '../../components/OutputTable';
import EmissionsTable from '../../components/EmissionsTable';
import SideMenu from '../../components/SideMenu';
import FormField from '../../components/FormField';
import FormSection from '../../components/FormSection';
import FormFieldTable from '../../components/FormFieldTable';
import EmmissionFieldTable from '../../components/EmmissionFieldTable';
import EmmissionVarFieldTable from '../../components/EmmissionVarFieldTable';
import CalculatorField from '../../components/CalculatorField';
import ThermoDynamicGraph from '../../components/ThermoDynamicGraph';
import StackedBarChart from '../../components/StackedBarChart';


const Advanced = ({data,exportCalc,def,solutionDef,errors,setData,menuOpen,defaultScenario})=>{
  const { t, i18n } = useTranslation();
  const [ active, setActive ] = useState("General");
  
  const [localScenario, setLocalScenario] = useState({...flatten(data.Scenario), InflationRatesPerYear: data.Scenario.InflationRatesPerYear});
  const [localSolution, setLocalSolution] = useState(flatten(data.Solution));
  const propNameKey = i18n.language === "dk" ? "DanishName" : "EnglishName";
  
  useEffect(()=>{
    setLocalScenario(flatten(data.Scenario));
    setLocalSolution(flatten(data.Solution));
  },[data]);

  const updateProp = (name, val) => {
    setData({...localScenario, [name]: val});
  };
  const updateLocalProp = (name, val) => {
    setLocalScenario({...localScenario, [name]: val});
  };
  return (
    <div className={`advanced ${menuOpen ? 'menu-open' : ''}`}>
      <SideMenu selected="advanced" showMenu={menuOpen} active={active} setActive={setActive} exportCalc={exportCalc} resetCalc={()=>{setData()}}/>    
      {
        active === "General" &&
        <div className="advanced__container">
          <div className="advanced__inner advanced__inner--left">

            <div className="advanced__inner__text">{t("advanced.generalText")}</div>
            <div className="advanced__inner__plot">
              <KeyValues 
                def={solutionDef}
                NPV={data.Solution.NPV}
                SimpleRepaymentPeriod={data.Solution.SimpleRepaymentPeriod}
                DiscountedTBT={data.Solution.DiscountedTBT}
                IRR={data.Solution.Future.IRR}
                ExpensesPerProperty={data.Solution.ExpensesPerProperty}
                TotalCostPerMWh={data.Solution.TotalCostPerMWh}
              />
            </div>
            <Form className="advanced__inner__form" size="mini"> 
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.generalSection.sectionGeneral")}</div>
                <FormField
                  name="General.SupplyPrerequisites.UseInflation"
                  type="bool" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.UseInflation"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.UseInflation"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.SupplyPrerequisites.UseDiscounting"
                  type="bool" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.UseDiscounting"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.UseDiscounting"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.SupplyPrerequisites.HeatPumpEffect"
                  type="select" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.HeatPumpEffect"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.HeatPumpEffect"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.SupplyPrerequisites.DistributionNetPricing"
                  type="select" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.DistributionNetPricing"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.DistributionNetPricing"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.SupplyPrerequisites.BoostingMethod"
                  type="select" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.BoostingMethod"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.BoostingMethod"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.SupplyPrerequisites.HeatpumpType"
                  type="select" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.HeatpumpType"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.HeatpumpType"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.SupplyPrerequisites.HeatType"
                  type="select" 
                  onchange={updateProp} 
                  value={localScenario["General.SupplyPrerequisites.HeatType"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.SupplyPrerequisites.HeatType"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.InitialInvestmentYear"
                  type="number" 
                  onchange={updateLocalProp}
                  onblur={updateProp} 
                  value={localScenario["General.InitialInvestmentYear"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.InitialInvestmentYear"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.IndexYearDiscounting"
                  type="number" 
                  onchange={updateLocalProp}
                  onblur={updateProp} 
                  value={localScenario["General.IndexYearDiscounting"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.IndexYearDiscounting"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.IndexYearInflation"
                  type="number" 
                  onchange={updateLocalProp}
                  onblur={updateProp} 
                  value={localScenario["General.IndexYearInflation"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.IndexYearInflation"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.VATRate"
                  type="number" 
                  onchange={updateLocalProp}
                  onblur={updateProp} 
                  value={localScenario["General.VATRate"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.VATRate"]} 
                  propNameKey={propNameKey} 
                />
              </FormSection>
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.generalSection.sectionTitleStandartHouse")}</div>
                <FormField
                  name="General.HouseData.SizeSquareMeters"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.HouseData.SizeSquareMeters"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.HouseData.SizeSquareMeters"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.HouseData.ConsumptionMegaWattHours"
                  type="number" 
                  onchange={updateLocalProp}
                  onblur={updateProp} 
                  value={localScenario["General.HouseData.ConsumptionMegaWattHours"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.HouseData.ConsumptionMegaWattHours"]} 
                  propNameKey={propNameKey} 
                />
              </FormSection>
              <FormSection accordion={true} accordionTitle={t("advanced.generalSection.sectionTitleInflation")}>
                <FormFieldTable 
                  path={"InflationRatesPerYear."}
                  head={[t("advanced.generalSection.years"), t("advanced.generalSection.inflation")]}
                  onchange={updateLocalProp}
                  onblur={updateProp} 
                  data={localScenario}
                />
              </FormSection>
            </Form>
          </div>
          <div className="advanced__right">
            <KeyValues 
              def={solutionDef}
              NPV={data.Solution.NPV}
              SimpleRepaymentPeriod={data.Solution.SimpleRepaymentPeriod}
              DiscountedTBT={data.Solution.DiscountedTBT}
              IRR={data.Solution.Future.IRR}
              ExpensesPerProperty={data.Solution.ExpensesPerProperty}
              TotalCostPerMWh={data.Solution.TotalCostPerMWh}
            />
          </div>
        </div>
      }
      {
        active === "CorporateFinance" &&
        <div className="advanced__container">
          <div className="advanced__inner advanced__inner--left">
            <div className="advanced__inner__text">{t("advanced.corporateText")}</div>
            <div className="advanced__inner__plot">
              <KeyValues 
                def={solutionDef}
                NPV={data.Solution.NPV}
                SimpleRepaymentPeriod={data.Solution.SimpleRepaymentPeriod}
                DiscountedTBT={data.Solution.DiscountedTBT}
                IRR={data.Solution.Future.IRR}
                ExpensesPerProperty={data.Solution.ExpensesPerProperty}
                TotalCostPerMWh={data.Solution.TotalCostPerMWh}
              />
              <AccumulatedBarChart data={data.Solution.Future.AccumulatedDiscountedNominal}/>
              <StackedBarChart
                data={data.Solution}
                def={solutionDef}
              />
            </div>
            <OutputTable
              data={data.Solution.Income}
              title={t('Income')}
              path="Income"
              rowsKeys={[
                "VariableHeatingPriceTotal",
                "FixedHeatingPriceTotal",
                "ExtraordinaryContribution",
                "MeterRental",
                "InvestmentContribution",
                "BranchlineContributionStart",
                "BranchlineContributionMeter",
                "TotalIndexWithoutContributions",
                "TotalIndex",
              ]} 
              def={solutionDef}/>
            <OutputTable
              data={data.Solution.Capex}
              title={t('CAPEX')}
              path="Capex"
              rowsKeys={[
                "DistributionNetNarrow",
                "DistributionNetWide",
                "BranchlinePipe",
                "PipeworkDrilling",
                "PipeworkAccessHole",
                "HeatPumps",
                "HeatPumpInstallation",
                "CentralHeatPumpWithInstallation",
                "GeothermalDrillings",
                "BrineCost",
                "BrinePumpStationCost",
                "ElectricMeters",
                "TransmissionLinePipe",
                "TransmissionLineDrilling",
                "TransmissionLineAccessholes",
                "TraditionalHeatingDistributionNet",
                "TotalIndex",
              ]} 
              def={solutionDef}/>
            <OutputTable
              data={data.Solution.Opex}
              title={t('OPEX')}
              path="Opex"
              rowsKeys={[
                "Electricity",
                "ServiceAgreement",
                "HeatFromExternalHeatSource",
                "TotalIndex",
              ]} 
              def={solutionDef}/>
            <Form className="advanced__inner__form" size="mini"> 
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.corporateSection.sectionPrices")}</div>
                <div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.oneTimeAmount")}</div>
                <FormField
                  name="General.DistrictHeatingPrices.InvestmentContribution"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.InvestmentContribution"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.InvestmentContribution"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.DistrictHeatingPrices.BranchlineContributionStart"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.BranchlineContributionStart"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.BranchlineContributionStart"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.DistrictHeatingPrices.BranchlineContributionMeter"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.BranchlineContributionMeter"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.BranchlineContributionMeter"]} 
                  propNameKey={propNameKey} 
                />

                <div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.variableAmount")}</div>
                <FormField
                  name="CorporateFinance.ElectricityPrice"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.ElectricityPrice"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.ElectricityPrice"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.DistrictHeatingPrices.ConsumerFixedPrice"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.ConsumerFixedPrice"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.ConsumerFixedPrice"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.DistrictHeatingPrices.ConsumerVariablePrice"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.ConsumerVariablePrice"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.ConsumerVariablePrice"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="General.DistrictHeatingPrices.HeatAcquisitionCentral"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.HeatAcquisitionCentral"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.HeatAcquisitionCentral"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="CorporateFinance.ColdDistrictHeatingContribution"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.ColdDistrictHeatingContribution"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.ColdDistrictHeatingContribution"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="General.DistrictHeatingPrices.MeterRental"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.DistrictHeatingPrices.MeterRental"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.DistrictHeatingPrices.MeterRental"]} 
                  propNameKey={propNameKey} 
                  />
              </FormSection>
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.corporateSection.sectionPrerequisites")}</div>
                <FormField
                  name="CorporateFinance.NumberOfResidences"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.NumberOfResidences"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.NumberOfResidences"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="CorporateFinance.ConnectionPercentage"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.ConnectionPercentage"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.ConnectionPercentage"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.NumberOfConnections"
                  disabled
                  value={localSolution["Intermediates.NumberOfConnections"] ? localSolution["Intermediates.NumberOfConnections"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.NumberOfConnections"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.TotalHeatedArea"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  disabled
                  value={localSolution["Intermediates.TotalHeatedArea"] ? localSolution["Intermediates.TotalHeatedArea"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.TotalHeatedArea"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.TotalHeatingNeed"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  disabled
                  value={localSolution["Intermediates.TotalHeatingNeed"] ? localSolution["Intermediates.TotalHeatingNeed"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.TotalHeatingNeed"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.TotalHeatUptake"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  disabled
                  value={localSolution["Intermediates.TotalHeatUptake"] ? localSolution["Intermediates.TotalHeatUptake"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.TotalHeatUptake"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.TotalHeatFromVertical"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  disabled
                  value={localSolution["Intermediates.TotalHeatFromVertical"] ? localSolution["Intermediates.TotalHeatFromVertical"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.TotalHeatFromVertical"]} 
                  propNameKey={propNameKey} 
                  />
              </FormSection>
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.corporateSection.sectionPiping")}</div>
                
                <div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.pipeSizes")}</div>

                <u><div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.pipeLengthDistribution")}</div></u>
                <FormField
                    name="CorporateFinance.TotalDistributionNetLength"
                    type="number"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    value={localScenario["CorporateFinance.TotalDistributionNetLength"]}
                    def={def}
                    helpText={""}
                    error={!!errors["CorporateFinance.TotalDistributionNetLength"]}
                    propNameKey={propNameKey}
                />
                <FormField
                    solution
                    name="Intermediates.ProjectedLength"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    disabled
                    value={localSolution["Intermediates.ProjectedLength"] ? localSolution["Intermediates.ProjectedLength"].toLocaleString() : ""}
                    def={solutionDef}
                    helpText=""
                    error={!!errors["Intermediates.ProjectedLength"]}
                    propNameKey={propNameKey}
                />
                <FormField
                    solution
                    name="Intermediates.TotalLengthWide"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    disabled
                    value={localSolution["Intermediates.TotalLengthWide"] ? localSolution["Intermediates.TotalLengthWide"].toLocaleString() : ""}
                    def={solutionDef}
                    helpText=""
                    error={!!errors["Intermediates.TotalLengthWide"]}
                    propNameKey={propNameKey}
                 />
                 <FormField
                    solution
                    name="Intermediates.TotalLengthNarrow"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    disabled
                    value={localSolution["Intermediates.TotalLengthNarrow"] ? localSolution["Intermediates.TotalLengthNarrow"].toLocaleString() : ""}
                    def={solutionDef}
                    helpText=""
                    error={!!errors["Intermediates.TotalLengthNarrow"]}
                    propNameKey={propNameKey}
              />

                <u><div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.pipeLengthBranchLine")}</div></u>
                <FormField
                    name="CorporateFinance.BranchLineLength"
                    type="number"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    value={localScenario["CorporateFinance.BranchLineLength"]}
                    def={def}
                    helpText={""}
                    error={!!errors["CorporateFinance.BranchLineLength"]}
                    propNameKey={propNameKey}
                />
                <FormField
                    solution
                    name="Intermediates.BranchlineTotalLength"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    disabled
                    value={localSolution["Intermediates.BranchlineTotalLength"] ? localSolution["Intermediates.BranchlineTotalLength"].toLocaleString() : ""}
                    def={solutionDef}
                    helpText=""
                    error={!!errors["Intermediates.BranchlineTotalLength"]}
                    propNameKey={propNameKey}
                />
                <FormField
                    solution
                    name="Intermediates.TotalLengthBranchLine"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    disabled
                    value={localSolution["Intermediates.TotalLengthBranchLine"] ? localSolution["Intermediates.TotalLengthBranchLine"].toLocaleString() : ""}
                    def={solutionDef}
                    helpText=""
                    error={!!errors["Intermediates.TotalLengthBranchLine"]}
                    propNameKey={propNameKey}
                />

                <u><div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.pipeLengthTransmission")}</div></u>
                <FormField
                    name="CorporateFinance.TransmissionLineLength"
                    type="number"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    value={localScenario["CorporateFinance.TransmissionLineLength"]}
                    def={def}
                    helpText={""}
                    error={!!errors["CorporateFinance.TransmissionLineLength"]}
                    propNameKey={propNameKey}
                />
                <FormField
                    solution
                    name="Intermediates.TotalLengthPipeTl"
                    onchange={updateLocalProp}
                    onblur={updateProp}
                    disabled
                    value={localSolution["Intermediates.TotalLengthPipeTl"] ? localSolution["Intermediates.TotalLengthPipeTl"].toLocaleString() : ""}
                    def={solutionDef}
                    helpText=""
                    error={!!errors["Intermediates.TotalLengthPipeTl"]}
                    propNameKey={propNameKey}
                />

                <div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.construction")}</div>
                <FormField
                  name="CorporateFinance.NumberDrillingHoles"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.NumberDrillingHoles"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.NumberDrillingHoles"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.NumberOfDrillingHoles"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  disabled
                  value={localSolution["Intermediates.NumberOfDrillingHoles"] ? localSolution["Intermediates.NumberOfDrillingHoles"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.NumberOfDrillingHoles"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  solution
                  name="Intermediates.NumberOfDrillingHolesTl"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  disabled
                  value={localSolution["Intermediates.NumberOfDrillingHolesTl"] ? localSolution["Intermediates.NumberOfDrillingHolesTl"].toLocaleString() : ""}
                  def={solutionDef} 
                  helpText=""
                  error={!!errors["Intermediates.NumberOfDrillingHolesTl"]} 
                  propNameKey={propNameKey} 
                  />

                <div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.drilling")}</div>
                <FormField
                  name="CorporateFinance.NumberGeothermalDrillingsPerHouse"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.NumberGeothermalDrillingsPerHouse"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.NumberGeothermalDrillingsPerHouse"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="CorporateFinance.PricePerGeothermalDrilling"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["CorporateFinance.PricePerGeothermalDrilling"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.PricePerGeothermalDrilling"]} 
                  propNameKey={propNameKey} 
                  />

                <div className="advanced__inner__form__section-subhead">{t("advanced.corporateSection.brine")}</div>
                <FormField
                  name="General.Brine.PriceBrine"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.Brine.PriceBrine"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.Brine.PriceBrine"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="General.Brine.BrineAmount"
                  type="number"
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.Brine.BrineAmount"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.Brine.BrineAmount"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="General.Brine.PricePumpStation"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.Brine.PricePumpStation"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.Brine.PricePumpStation"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="General.Brine.NumberOfPumpStations"
                  type="number" 
                  onchange={updateLocalProp} 
                  onblur={updateProp} 
                  value={localScenario["General.Brine.NumberOfPumpStations"]}
                  def={def} 
                  helpText=""
                  error={!!errors["General.Brine.NumberOfPumpStations"]} 
                  propNameKey={propNameKey} 
                  />
              </FormSection>
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.corporateSection.sectionEconomicPrerequisites")}</div>
                <FormField
                  name="CorporateFinance.DiscountRate"
                  type="number" 
                  onchange={updateProp} 
                  value={localScenario["CorporateFinance.DiscountRate"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.DiscountRate"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="CorporateFinance.LifeExpectancy"
                  type="number" 
                  onchange={updateProp} 
                  value={localScenario["CorporateFinance.LifeExpectancy"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.LifeExpectancy"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="CorporateFinance.RiskPool"
                  type="number" 
                  onchange={updateProp} 
                  value={localScenario["CorporateFinance.RiskPool"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.RiskPool"]} 
                  propNameKey={propNameKey} 
                />
              </FormSection>
              <FormSection>
                <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.corporateSection.sectionOtherConstants")}</div>
                <FormField
                  name="CorporateFinance.COPFactor"
                  type="number" 
                  onchange={updateProp} 
                  value={localScenario["CorporateFinance.COPFactor"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.COPFactor"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="CorporateFinance.HorizontalHeatUptakeFactor"
                  type="number" 
                  onchange={updateProp} 
                  value={localScenario["CorporateFinance.HorizontalHeatUptakeFactor"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.HorizontalHeatUptakeFactor"]} 
                  propNameKey={propNameKey} 
                />
                <FormField
                  name="CorporateFinance.LifeExpectancy"
                  type="number" 
                  onchange={updateProp} 
                  value={localScenario["CorporateFinance.LifeExpectancy"]}
                  def={def} 
                  helpText=""
                  error={!!errors["CorporateFinance.LifeExpectancy"]} 
                  propNameKey={propNameKey} 
                />

              </FormSection>
            </Form>
          </div>
          <div className="advanced__right">
            <KeyValues 
                def={solutionDef}
                NPV={data.Solution.NPV}
                SimpleRepaymentPeriod={data.Solution.SimpleRepaymentPeriod}
                DiscountedTBT={data.Solution.DiscountedTBT}
                IRR={data.Solution.Future.IRR}
                ExpensesPerProperty={data.Solution.ExpensesPerProperty}
                TotalCostPerMWh={data.Solution.TotalCostPerMWh}
              />
            <AccumulatedBarChart data={data.Solution.Future.AccumulatedDiscountedNominal}/>
            <StackedBarChart
              data={data.Solution}
              def={solutionDef}
              />
          </div>

        </div>
      }
      {
        active === "Sensitivity" &&
        <div className="advanced__inner">
          <SensitivityAnalysis scenario={data.Scenario} def={def} defaultScenario={defaultScenario}/>
        </div>
      }
      {
        active === "UserEconomy" &&
        <div className="advanced__inner">
          <div className="advanced__inner__text">{t("advanced.userText")}</div>
          <BusinessCasesTable data={data.Solution.BusinessCases} def={solutionDef}/>
          <Form className="advanced__inner__form" size="mini"> 
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.userSection.sectionHouseSizeHeat")}</div>
              <FormField
                name="General.HouseData.SizeSquareMeters"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HouseData.SizeSquareMeters"]}
                def={def} 
                helpText=""
                error={!!errors["General.HouseData.SizeSquareMeters"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="General.HouseData.ConsumptionMegaWattHours"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HouseData.ConsumptionMegaWattHours"]}
                def={def} 
                helpText=""
                error={!!errors["General.HouseData.ConsumptionMegaWattHours"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.userSection.sectionPricesInvestment")}</div>
              <FormField
                name="UserEconomy.PriceNewHeaterOil"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceNewHeaterOil"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceNewHeaterOil"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceNewHeaterNaturalGas"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceNewHeaterNaturalGas"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceNewHeaterNaturalGas"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceNewHeaterWoodPellets"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceNewHeaterWoodPellets"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceNewHeaterWoodPellets"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceAirWaterHeatPump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceAirWaterHeatPump"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceAirWaterHeatPump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceGeothermalHeatingSystem"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceGeothermalHeatingSystem"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceGeothermalHeatingSystem"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.RadiatorSystem"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.RadiatorSystem"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.RadiatorSystem"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.LifeExpectancyYears"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.LifeExpectancyYears"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.LifeExpectancyYears"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.userSection.sectionFuelPrices")}</div>
              <FormField
                name="UserEconomy.PriceOil"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceOil"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceOil"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceNaturalGas"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceNaturalGas"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceNaturalGas"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceWoodPellets"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceWoodPellets"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceWoodPellets"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.PriceElectricity"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.PriceElectricity"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.PriceElectricity"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.userSection.sectionTechnicalConstants")}</div>
              <FormField
                name="UserEconomy.CombustionValueOil"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.CombustionValueOil"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.CombustionValueOil"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.CombustionValueNaturalGas"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.CombustionValueNaturalGas"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.CombustionValueNaturalGas"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.CombustionValueWoodPellets"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.CombustionValueWoodPellets"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.CombustionValueWoodPellets"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.COPFactorAirWaterHeatPump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.COPFactorAirWaterHeatPump"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.COPFactorAirWaterHeatPump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.COPFactorGeothermalHeatPump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.COPFactorGeothermalHeatPump"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.COPFactorGeothermalHeatPump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.FurnaceEfficiencyOil"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.FurnaceEfficiencyOil"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.FurnaceEfficiencyOil"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.FurnaceEfficiencyNaturalGas"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.FurnaceEfficiencyNaturalGas"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.FurnaceEfficiencyNaturalGas"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="UserEconomy.FurnaceEfficiencyWoodPellet"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["UserEconomy.FurnaceEfficiencyWoodPellet"]}
                def={def} 
                helpText=""
                error={!!errors["UserEconomy.FurnaceEfficiencyWoodPellet"]} 
                propNameKey={propNameKey} 
              />

            </FormSection>
          </Form>
        </div>
      }
      {
        active === "Emmissions" &&
        <div className="advanced__inner">
          <div className="advanced__inner__text">{t("advanced.emissionText")}</div>
          <Form className="advanced__inner__form" size="mini"> 
            <EmissionsTable data={data.Solution.Emissions} def={solutionDef}/>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.emissionSection.sectionDistributionKey")}</div>
              <FormField
                name="EmissionCoefficients.DistributionKey.OilBurner"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["EmissionCoefficients.DistributionKey.OilBurner"]}
                def={def} 
                helpText=""
                error={!!errors["EmissionCoefficients.DistributionKey.OilBurner"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="EmissionCoefficients.DistributionKey.GasBurner"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["EmissionCoefficients.DistributionKey.GasBurner"]}
                def={def} 
                helpText=""
                error={!!errors["EmissionCoefficients.DistributionKey.GasBurner"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="EmissionCoefficients.DistributionKey.WoodPelletStove"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["EmissionCoefficients.DistributionKey.WoodPelletStove"]}
                def={def} 
                helpText=""
                error={!!errors["EmissionCoefficients.DistributionKey.WoodPelletStove"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="EmissionCoefficients.DistributionKey.HeatPump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["EmissionCoefficients.DistributionKey.HeatPump"]}
                def={def} 
                helpText=""
                error={!!errors["EmissionCoefficients.DistributionKey.HeatPump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="EmissionCoefficients.DistributionKey.Geothermal"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["EmissionCoefficients.DistributionKey.Geothermal"]}
                def={def} 
                helpText=""
                error={!!errors["EmissionCoefficients.DistributionKey.Geothermal"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="EmissionCoefficients.DistributionKey.ElectricHeating"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["EmissionCoefficients.DistributionKey.ElectricHeating"]}
                def={def} 
                helpText=""
                error={!!errors["EmissionCoefficients.DistributionKey.ElectricHeating"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
              
            <FormSection noBorder accordion={true} accordionTitle={t("advanced.emissionSection.sectionConstantCoefficients")}>
              <EmmissionFieldTable
                def={def}
                onchange={updateLocalProp}
                onblur={updateProp} 
                data={localScenario}
              />
            </FormSection>
              
            <FormSection noBorder accordion={true} accordionTitle={t("advanced.emissionSection.sectionVariableCoefficients")}>
              <EmmissionVarFieldTable
                def={def}
                onchange={updateLocalProp}
                onblur={updateProp} 
                data={localScenario}
              />
            </FormSection>

          </Form>
        </div>
      }
      {
        active === "Prices" &&
        <div className="advanced__inner">
          <div className="advanced__inner__text">{t("advanced.priceText")}</div>
          <Form className="advanced__inner__form" size="mini"> 
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.priceSection.sectionHeatpumps")}</div>
              <div className="advanced__inner__form__section-subhead">{t("advanced.priceSection.individualPumps")}</div>
              <FormField
                name="General.HeatpumpPrices.kW6"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HeatpumpPrices.kW6"]}
                def={def} 
                helpText=""
                error={!!errors["General.HeatpumpPrices.kW6"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="General.HeatpumpPrices.kW8"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HeatpumpPrices.kW8"]}
                def={def} 
                helpText=""
                error={!!errors["General.HeatpumpPrices.kW8"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="General.HeatpumpPrices.kW10"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HeatpumpPrices.kW10"]}
                def={def} 
                helpText=""
                error={!!errors["General.HeatpumpPrices.kW10"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="General.HeatpumpPrices.Installation"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HeatpumpPrices.Installation"]}
                def={def} 
                helpText=""
                error={!!errors["General.HeatpumpPrices.Installation"]} 
                propNameKey={propNameKey} 
              />
              <div className="advanced__inner__form__section-subhead">{t("advanced.priceSection.centralPumps")}</div>
              <FormField
                name="General.HeatpumpPrices.kW250AirToWater"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HeatpumpPrices.kW250AirToWater"]}
                def={def} 
                helpText=""
                error={!!errors["General.HeatpumpPrices.kW250AirToWater"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="General.HeatpumpPrices.kW250WaterToWater"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["General.HeatpumpPrices.kW250WaterToWater"]}
                def={def} 
                helpText=""
                error={!!errors["General.HeatpumpPrices.kW250WaterToWater"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.priceSection.sectionPiping")}</div>
              <div className="advanced__inner__form__section-subhead">{t("advanced.priceSection.transmission")}</div>
              <FormField
                name="CorporateFinance.TransmissionLineMeterPrice"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.TransmissionLineMeterPrice"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.TransmissionLineMeterPrice"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="CorporateFinance.PriceDistributionNetWide"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PriceDistributionNetWide"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PriceDistributionNetWide"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="CorporateFinance.PriceDistributionNetNarrow"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PriceDistributionNetNarrow"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PriceDistributionNetNarrow"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="CorporateFinance.PriceBranchlinePipe"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PriceBranchlinePipe"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PriceBranchlinePipe"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="CorporateFinance.PricePipeworkDrilling"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PricePipeworkDrilling"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PricePipeworkDrilling"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="CorporateFinance.PricePipeworkAccessHole"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PricePipeworkAccessHole"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PricePipeworkAccessHole"]} 
                propNameKey={propNameKey} 
              />
              <div className="advanced__inner__form__section-subhead">{t("advanced.priceSection.gauges")}</div>
              <FormField
                name="CorporateFinance.PriceElectricMeter"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PriceElectricMeter"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PriceElectricMeter"]} 
                propNameKey={propNameKey} 
              />

              <div className="advanced__inner__form__section-subhead"></div>
              <FormField
                name="CorporateFinance.PriceServiceHeatPump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PriceServiceHeatPump"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PriceServiceHeatPump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="CorporateFinance.PriceSeries3Pipe"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["CorporateFinance.PriceSeries3Pipe"]}
                def={def} 
                helpText=""
                error={!!errors["CorporateFinance.PriceSeries3Pipe"]} 
                propNameKey={propNameKey} 
              />

            </FormSection>
          </Form>
        </div>
      }
      {
        active === "Thermodynamics" &&
        <div className="advanced__inner">
          <div className="advanced__inner__text">{t("advanced.thermodynamicText")}</div>
          <ThermoDynamicGraph data={data}/>
          <Form className="advanced__inner__form" size="mini"> 
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.thermodynamicsSection.sectionTemp")}</div>
              <FormField
                name="Thermodynamics.Transmission.TemperatureComingOutOfHouses"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.TemperatureComingOutOfHouses"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.TemperatureComingOutOfHouses"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Transmission.TemperatureReturnFromHeatpump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.TemperatureReturnFromHeatpump"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.TemperatureReturnFromHeatpump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Transmission.TemperatureFlowFromHeatPump"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.TemperatureFlowFromHeatPump"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.TemperatureFlowFromHeatPump"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Transmission.TemperatureComingOutOfPlant"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.TemperatureComingOutOfPlant"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.TemperatureComingOutOfPlant"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Transmission.LengthPipePex"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.LengthPipePex"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.LengthPipePex"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Transmission.LengthPipeTotal"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.LengthPipeTotal"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.LengthPipeTotal"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.thermodynamicsSection.sectionHeatNeed")}</div>
              <FormField
                name="Thermodynamics.Transmission.TotalCityPowerNeed"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.TotalCityPowerNeed"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.TotalCityPowerNeed"]} 
                propNameKey={propNameKey} 
              />             
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.thermodynamicsSection.sectionPrerequisites")}</div>
              <FormField
                name="Thermodynamics.Transmission.VelocityPex"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.VelocityPex"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.VelocityPex"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.VelocityIso"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.VelocityIso"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.VelocityIso"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.SelectedPexPipe"
                type="select" 
                onchange={updateProp} 
                options={data && data.Scenario && data.Scenario.Thermodynamics && data.Scenario.Thermodynamics.PipeSpecifications && data.Scenario.Thermodynamics.PipeSpecifications.PEX ? data.Scenario.Thermodynamics.PipeSpecifications.PEX.map(pipe=>({key: pipe.Dn, value: pipe.Dn, text: pipe.Dn})) : []}
                value={localScenario["Thermodynamics.Transmission.SelectedPexPipe"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.SelectedPexPipe"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.SelectedIsoPipe"
                type="select" 
                onchange={updateProp} 
                options={data && data.Scenario && data.Scenario.Thermodynamics && data.Scenario.Thermodynamics.PipeSpecifications && data.Scenario.Thermodynamics.PipeSpecifications.ISO ? data.Scenario.Thermodynamics.PipeSpecifications.ISO.map(pipe=>({key: pipe.Dn, value: pipe.Dn, text: pipe.Dn})) : []}
                value={localScenario["Thermodynamics.Transmission.SelectedIsoPipe"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.SelectedIsoPipe"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                solution
                name="Thermodynamics.Transmission.PEXFlow"
                disabled
                onchange={updateLocalProp}
                onblur={updateProp}
                value={localSolution["Thermodynamics.Transmission.PEXFlow"] ? localSolution["Thermodynamics.Transmission.SelectedPexPipe.Flow"].toLocaleString() : ""}
                def={solutionDef}
                helpText=""
                error={!!errors["Thermodynamics.Transmission.PEXFlow"]}
                propNameKey={propNameKey}
              />
              <FormField
                solution
                name="Thermodynamics.Transmission.ISOFlow"
                disabled
                onchange={updateLocalProp}
                onblur={updateProp}
                value={localSolution["Thermodynamics.Transmission.ISOFlow"] ? localSolution["Thermodynamics.Transmission.ISOFlow"].toLocaleString() : ""}
                def={solutionDef}
                helpText=""
                error={!!errors["Thermodynamics.Transmission.ISOFlow"]}
                propNameKey={propNameKey}
              />
              <FormField
                solution
                name="Thermodynamics.Transmission.PEXEnergyCapacity"
                disabled
                onchange={updateLocalProp}
                onblur={updateProp}
                value={localSolution["Thermodynamics.Transmission.PEXEnergyCapacity"] ? localSolution["Thermodynamics.Transmission.PEXEnergyCapacity"].toLocaleString() : ""}
                def={solutionDef}
                helpText=""
                error={!!errors["Thermodynamics.Transmission.PEXEnergyCapacity"]}
                propNameKey={propNameKey}
              />
              <FormField
                solution
                name="Thermodynamics.Transmission.ISOEnergyCapacity"
                disabled
                onchange={updateLocalProp}
                onblur={updateProp}
                value={localSolution["Thermodynamics.Transmission.ISOEnergyCapacity"] ? localSolution["Thermodynamics.Transmission.ISOEnergyCapacity"].toLocaleString() : ""}
                def={solutionDef}
                helpText=""
                error={!!errors["Thermodynamics.Transmission.ISOEnergyCapacity"]}
                propNameKey={propNameKey}
              />
              <FormField
                name="Thermodynamics.Transmission.PipeGroundDepth"
                type="number"
                onchange={updateLocalProp}
                onblur={updateProp}
                value={localScenario["Thermodynamics.Transmission.PipeGroundDepth"]}
                def={def}
                helpText=""
                error={!!errors["Thermodynamics.Transmission.PipeGroundDepth"]}
                propNameKey={propNameKey}
              />
              <FormField
                name="Thermodynamics.Transmission.UnpavedAreaPercentage"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.UnpavedAreaPercentage"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.UnpavedAreaPercentage"]} 
                propNameKey={propNameKey} 
              />             
            </FormSection>
            <FormSection>
              <FormField
                name="Thermodynamics.Transmission.TemperatureOutsidePipe"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.TemperatureOutsidePipe"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.TemperatureOutsidePipe"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.PipeGroundDepth"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.PipeGroundDepth"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.PipeGroundDepth"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.HeatTransferInGround"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.HeatTransferInGround"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.HeatTransferInGround"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.PexMaterialHeatTransferCoefficient"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.PexMaterialHeatTransferCoefficient"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.PexMaterialHeatTransferCoefficient"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.SteelMaterialHeatTransferCoefficient"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.SteelMaterialHeatTransferCoefficient"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.SteelMaterialHeatTransferCoefficient"]} 
                propNameKey={propNameKey} 
              />             
              <FormField
                name="Thermodynamics.Transmission.IsolationMaterialHeatTransferCoefficient"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Transmission.IsolationMaterialHeatTransferCoefficient"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Transmission.IsolationMaterialHeatTransferCoefficient"]} 
                propNameKey={propNameKey} 
              />             
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.thermodynamicsSection.sectionSupply")}</div>
              <FormField
                name="Thermodynamics.Supply.IsoEfficiency"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.IsoEfficiency"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.IsoEfficiency"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.IsoRoughnessFactor"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.IsoRoughnessFactor"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.IsoRoughnessFactor"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.KinematicViscosityAt25C"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.KinematicViscosityAt25C"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.KinematicViscosityAt25C"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.KinematicViscosityAt90C"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.KinematicViscosityAt90C"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.KinematicViscosityAt90C"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.MaxEffectFromPlant"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.MaxEffectFromPlant"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.MaxEffectFromPlant"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.NoPumpsInDistribution"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.NoPumpsInDistribution"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.NoPumpsInDistribution"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.NoPumpsInTransmission"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.NoPumpsInTransmission"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.NoPumpsInTransmission"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PexEfficiency"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PexEfficiency"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PexEfficiency"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PexRoughnessFactor"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PexRoughnessFactor"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PexRoughnessFactor"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PriceElectricityHeatpumps"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PriceElectricityHeatpumps"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PriceElectricityHeatpumps"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PriceElectricityWaterpumps"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PriceElectricityWaterpumps"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PriceElectricityWaterpumps"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PriceRefillPrimingWater"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PriceRefillPrimingWater"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PriceRefillPrimingWater"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PumpstationPrice"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PumpstationPrice"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PumpstationPrice"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.RuntimePerYear"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.RuntimePerYear"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.RuntimePerYear"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Supply.PrimingWaterLossPerYear"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Supply.PrimingWaterLossPerYear"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Supply.PrimingWaterLossPerYear"]} 
                propNameKey={propNameKey} 
              />     
            </FormSection>
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.thermodynamicsSection.sectionDistribution")}</div>
              <FormField
                name="Thermodynamics.Distribution.AverageRadiusDistributionNet"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Distribution.AverageRadiusDistributionNet"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Distribution.AverageRadiusDistributionNet"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Distribution.LorentzEfficiency"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Distribution.LorentzEfficiency"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Distribution.LorentzEfficiency"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Distribution.PriceMaintenanceWaterpumpPerYear"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Distribution.PriceMaintenanceWaterpumpPerYear"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Distribution.PriceMaintenanceWaterpumpPerYear"]} 
                propNameKey={propNameKey} 
              />
              <FormField
                name="Thermodynamics.Distribution.PriceWaterpumpCentral"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Distribution.PriceWaterpumpCentral"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Distribution.PriceWaterpumpCentral"]} 
                propNameKey={propNameKey} 
              />
            </FormSection>
          
            <FormSection>
              <div className="advanced__inner__form__section-head advanced__inner__form__section-head--no-top">{t("advanced.thermodynamicsSection.sectionConsumer")}</div>
              <FormField
                name="Thermodynamics.Consumer.Runtime"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Consumer.Runtime"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Consumer.Runtime"]} 
                propNameKey={propNameKey} 
              />     
              <FormField
                name="Thermodynamics.Consumer.NumberOfResidences"
                type="number" 
                onchange={updateLocalProp} 
                onblur={updateProp} 
                value={localScenario["Thermodynamics.Consumer.NumberOfResidences"]}
                def={def} 
                helpText=""
                error={!!errors["Thermodynamics.Consumer.NumberOfResidences"]} 
                propNameKey={propNameKey} 
              />     
            </FormSection>
          </Form>
        </div>
      }
    </div>
  );
}

export default Advanced;

