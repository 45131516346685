import './index.scss';
import { useTranslation } from 'react-i18next';
import { parseDefProp, parseUnit } from '../../services/utils';

const KeyValues = ({NPV,SimpleRepaymentPeriod,IRR,ExpensesPerProperty,TotalCostPerMWh, def, DiscountedTBT})=>{
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;
  
  const NPVProps = parseDefProp(def, `NPV`);
  const SimpleRepaymentPeriodProps = parseDefProp(def, `SimpleRepaymentPeriod`);
  const DiscountedTBTProps = parseDefProp(def, `DiscountedTBT`);
  const ExpensesPerPropertyProps = parseDefProp(def, `ExpensesPerProperty`);
  const PricePerMWhProps = parseDefProp(def, `TotalCostPerMWh`);
  const IRRProps = parseDefProp(def, `Future.IRR`);

  return (
    <div className="key-values">
      <div className="key-values__head">
        {t("keyValues.title")}
      </div>
      <div className="key-values__row">
        <div className="key-values__row__column">
          {NPVProps && NPVProps.Title ? NPVProps.Title[languageCode] : t("keyValues.NPV")}
        </div>  
        <div className="key-values__row__column">
          <span>{NPV.toLocaleString()}</span>
          <span>{parseUnit(NPVProps.PropertyUnit, i18n.language)}</span>
        </div>  
      </div>    
      <div className="key-values__row">
        <div className="key-values__row__column">
          {SimpleRepaymentPeriodProps && SimpleRepaymentPeriodProps.Title ? SimpleRepaymentPeriodProps.Title[languageCode] : t("keyValues.SimpleRepaymentPeriod")}
        </div>  
        <div className="key-values__row__column">
          <span>{SimpleRepaymentPeriod}</span>
          <span>{parseUnit(SimpleRepaymentPeriodProps.PropertyUnit, i18n.language)}</span>
        </div>  
      </div>    
      <div className="key-values__row">
        <div className="key-values__row__column">
          {DiscountedTBTProps && DiscountedTBTProps.Title ? DiscountedTBTProps.Title[languageCode] : t("keyValues.DiscountedTBT")}
        </div>  
        <div className="key-values__row__column">
          <span>{DiscountedTBT}</span>
          <span>{parseUnit(DiscountedTBTProps.PropertyUnit, i18n.language)}</span>
        </div>  
      </div>    
      <div className="key-values__row">
        <div className="key-values__row__column">
          {IRRProps && IRRProps.Title ? IRRProps.Title[languageCode] : t("keyValues.IRR")}
        </div>  
        <div className="key-values__row__column">
          <span>{IRR}</span>
          <span>{parseUnit(IRRProps.PropertyUnit, i18n.language)}</span>
        </div>  
      </div>    
      <div className="key-values__row">
        <div className="key-values__row__column">
          {ExpensesPerPropertyProps && ExpensesPerPropertyProps.Title ? ExpensesPerPropertyProps.Title[languageCode] : t("keyValues.ExpensesPerProperty")}
        </div>  
        <div className="key-values__row__column">
          <span>{ExpensesPerProperty.toLocaleString()}</span>
          <span>{parseUnit(ExpensesPerPropertyProps.PropertyUnit, i18n.language)}</span>
        </div>  
      </div>    
      <div className="key-values__row">
        <div className="key-values__row__column">
          {PricePerMWhProps && PricePerMWhProps.Title ? PricePerMWhProps.Title[languageCode] : t("keyValues.PricePerMWh")}
        </div>  
        <div className="key-values__row__column">
          <span>{TotalCostPerMWh.toLocaleString()}</span>
          <span>{parseUnit(PricePerMWhProps.PropertyUnit, i18n.language)}</span>
        </div>  
      </div>    
    </div>
  );
}

export default KeyValues;