import './index.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { parseDefProp, parseUnit } from '../../services/utils';

const BusinessCasesTable = ({data, def})=>{
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;
  //const _cases = ["ColdDistrictHeating", "OilBurner", "NaturalGasBurner", "WoodPelletBurner", "HeatPump", "GeothermalInstallation", "ElectricHeating"];
  const _cases = ["ColdDistrictHeating", "DistrictHeating", "OilBurner", "NaturalGasBurner", "WoodPelletBurner", "HeatPump", "GeothermalInstallation", "ElectricHeating"];

  return (
    <div className="business-cases-table">
      <Table celled unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell key={`head`}></Table.HeaderCell>
            {
              _cases.map((_case)=><Table.HeaderCell key={`head-${_case}`}>{t(`BusinessCases.${_case}`)}</Table.HeaderCell>)
            }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell className="business-cases-table__section-head">{t("BusinessCases.InvestmentCost")}</Table.Cell>
            {_cases.map((_case,i)=><Table.Cell key={i}></Table.Cell>)}
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.InstallationCosts")} (${parseUnit(parseDefProp(def, 'BusinessCases.OilBurner.InstallationCosts').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`InstallationCosts-${_case}`}>{data[_case]['InstallationCosts'] ? data[_case]['InstallationCosts'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.InvestmentGrant")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.InvestmentContribution').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`InvestmentGrant-${_case}`}>{data[_case]['InvestmentContribution'] ? data[_case]['InvestmentContribution'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.BranchLineContribution")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.BranchLineContribution').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`BranchLineContribution-${_case}`}>{data[_case]['BranchLineContribution'] ? data[_case]['BranchLineContribution'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.GasLineConnection")} (${parseUnit(parseDefProp(def, 'BusinessCases.NaturalGasBurner.GasLineConnection').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`GasLineConnection-${_case}`}>{data[_case]['GasLineConnection'] ? data[_case]['GasLineConnection'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.ChimneyConnection")} (${parseUnit(parseDefProp(def, 'BusinessCases.WoodPelletBurner.ChimneyConnection').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`ChimneyConnection-${_case}`}>{data[_case]['ChimneyConnection'] ? data[_case]['ChimneyConnection'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row className="business-cases-table__section-summation">
            <Table.Cell>{`${t("BusinessCases.TotalInvest")} (${parseUnit(parseDefProp(def, 'BusinessCases.WoodPelletBurner.TotalInvestment').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`TotalInvestment-${_case}`}>{data[_case]['TotalInvestment'] ? data[_case]['TotalInvestment'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>

          <Table.Row>
            <Table.Cell>{""}</Table.Cell>
            {_cases.map((_case,i)=><Table.Cell key={`empty1-${_case}`}>{""}</Table.Cell>)}
          </Table.Row>
          
          <Table.Row>
            <Table.Cell className="business-cases-table__section-head">{t("BusinessCases.ServiceCost")}</Table.Cell>
            {_cases.map((_case,i)=><Table.Cell textAlign='right' key={`ServiceCost-${_case}`}>{data[_case]['ServiceCost'] ? data[_case]['ServiceCost'].toLocaleString() : ""}</Table.Cell>)}
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.Fuel")} (${parseUnit(parseDefProp(def, 'BusinessCases.WoodPelletBurner.Fuel').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`Fuel-${_case}`}>{data[_case]['Fuel'] ? data[_case]['Fuel'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.ElectricityUse")} (${parseUnit(parseDefProp(def, 'BusinessCases.OilBurner.ElectricityUse').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`ElectricityUse-${_case}`}>{data[_case]['ElectricityUse'] ? data[_case]['ElectricityUse'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.DistrictHeatingPerSquareMeter")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.DistrictHeatingPerSquareMeter').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`DistrictHeatingPerSquareMeter-${_case}`}>{data[_case]['DistrictHeatingPerSquareMeter'] ? data[_case]['DistrictHeatingPerSquareMeter'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.DistrictHeatingPerMWh")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.DistrictHeatingPerMWh').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`DistrictHeatingPerMWh-${_case}`}>{data[_case]['DistrictHeatingPerMWh'] ? data[_case]['DistrictHeatingPerMWh'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.ServiceAndMaintenance")} (${parseUnit(parseDefProp(def, 'BusinessCases.OilBurner.ServiceAndMaintenance').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`ServiceAndMaintenance-${_case}`}>{data[_case]['ServiceAndMaintenance'] ? data[_case]['ServiceAndMaintenance'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.GaugeRental")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.HeatMeterRental').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`GaugeRental-${_case}`}>{data[_case]['HeatMeterRental'] ? data[_case]['HeatMeterRental'].toLocaleString(): ""} </Table.Cell>)
            }
          </Table.Row>
          <Table.Row className="business-cases-table__section-summation">
            <Table.Cell>{`${t("BusinessCases.YearlyCost")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.AnnualVariableExpenses').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`AnnualVariableExpenses-${_case}`}>{data[_case]['AnnualVariableExpenses'] ? data[_case]['AnnualVariableExpenses'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>

          <Table.Row>
            <Table.Cell>{""}</Table.Cell>
            {_cases.map((_case,i)=><Table.Cell key={`empty1-${_case}`}>{""}</Table.Cell>)}
          </Table.Row>

          <Table.Row>
            <Table.Cell className="business-cases-table__section-head">{t("BusinessCases.TotalYearlyCost")}</Table.Cell>
            {_cases.map((_case,i)=><Table.Cell textAlign='right' key={`TotalYearlyCost-${_case}`}>{""}</Table.Cell>)}
          </Table.Row>          
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.YearlyCost")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.AnnualVariableExpenses').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`AnnualVariableExpenses-${_case}`}>{data[_case]['AnnualVariableExpenses'] ? data[_case]['AnnualVariableExpenses'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>          
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.LifeExpectancy")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.LifeExpectancy').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`LifeExpectancy-${_case}`}>{data[_case]['LifeExpectancy'] ? data[_case]['LifeExpectancy'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>          
          <Table.Row>
            <Table.Cell>{`${t("BusinessCases.LifeExpectancyInvest")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.InvestmentPerYearOfLife').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`InvestmentPerYearOfLife-${_case}`}>{data[_case]['InvestmentPerYearOfLife'] ? data[_case]['InvestmentPerYearOfLife'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>          
          <Table.Row className="business-cases-table__section-summation">
            <Table.Cell>{`${t("BusinessCases.TotalYearlyCost")} (${parseUnit(parseDefProp(def, 'BusinessCases.ColdDistrictHeating.TotalAnnualExpenses').PropertyUnit, i18n.language)})`}</Table.Cell>
            {
              _cases.map((_case,i)=><Table.Cell textAlign='right' key={`TotalAnnualExpenses-${_case}`}>{data[_case]['TotalAnnualExpenses'] ? data[_case]['TotalAnnualExpenses'].toLocaleString() : ""} </Table.Cell>)
            }
          </Table.Row>          
        </Table.Body>
      </Table>
    </div>
  );
}

export default BusinessCasesTable;