import './index.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyValues from "../../components/KeyValues";
import AccumulatedBarChart from "../../components/AccumulatedBarChart";
import SensitivityAnalysis from '../../components/SensitivityAnalysis';
import BusinessCasesTable from '../../components/BusinessCasesTable';
import OutputTable from '../../components/OutputTable';
import EmissionsTable from '../../components/EmissionsTable';
import SideMenu from '../../components/SideMenu';
import StackedBarChart from '../../components/StackedBarChart';


const Results = ({data,exportCalc,def, solutionDef, menuOpen, setData, defaultScenario})=>{
  const { t } = useTranslation();
  const [ active, setActive ] = useState("KeyValues");

  return (
    <div className={`results ${menuOpen ? 'menu-open' : ''}`}>
      <SideMenu showMenu={menuOpen} selected="results" active={active} setActive={setActive} exportCalc={exportCalc} resetCalc={()=>{setData()}}/>    
      {
        active === "KeyValues" &&
        <div className="results__inner">
          <div className="advanced__inner__text">{t("results.generalText")}</div>
          <KeyValues 
            NPV={data.Solution.NPV}
            def={solutionDef}
            SimpleRepaymentPeriod={data.Solution.SimpleRepaymentPeriod}
            DiscountedTBT={data.Solution.DiscountedTBT}
            IRR={data.Solution.Future.IRR}
            ExpensesPerProperty={data.Solution.ExpensesPerProperty}
            TotalCostPerMWh={data.Solution.TotalCostPerMWh}
            />
        </div>
      }
      {
        active === "CorporateFinance" &&
        <div className="results__inner">
          <div className="advanced__inner__text">{t("results.corporateText")}</div>
          <div className="results__inner__stacked">
            <StackedBarChart
              data={data.Solution}
              def={solutionDef}
              />
          </div>

          <OutputTable
            data={data.Solution.Income}
            title={t('Income')}
            path="Income"
            rowsKeys={[
              "VariableHeatingPriceTotal",
              "FixedHeatingPriceTotal",
              "ExtraordinaryContribution",
              "MeterRental",
              "InvestmentContribution",
              "BranchlineContributionStart",
              "BranchlineContributionMeter",
              "TotalIndexWithoutContributions",
              "TotalIndex",
            ]} 
            def={solutionDef}/>
          <OutputTable
            data={data.Solution.Capex}
            title={t('CAPEX')}
            path="Capex"
            rowsKeys={[
              "DistributionNetNarrow",
              "DistributionNetWide",
              "BranchlinePipe",
              "PipeworkDrilling",
              "PipeworkAccessHole",
              "HeatPumps",
              "HeatPumpInstallation",
              "CentralHeatPumpWithInstallation",
              "GeothermalDrillings",
              "BrineCost",
              "BrinePumpStationCost",
              "ElectricMeters",
              "TransmissionLinePipe",
              "TransmissionLineDrilling",
              "TransmissionLineAccessholes",
              "TraditionalHeatingDistributionNet",
              "TotalIndex",
            ]} 
            def={solutionDef}/>
          <OutputTable
            data={data.Solution.Opex}
            title={t('OPEX')}
            path="Opex"
            rowsKeys={[
              "Electricity",
              "ServiceAgreement",
              "HeatFromExternalHeatSource",
              "TotalIndex",
            ]} 
            def={solutionDef}/>
          <AccumulatedBarChart data={data.Solution.Future.AccumulatedDiscountedNominal}/>
          <SensitivityAnalysis scenario={data.Scenario} def={def} defaultScenario={defaultScenario}/>
        </div>
      }
      {
        active === "UserEconomy" &&
        <div className="results__inner">
          <div className="advanced__inner__text">{t("results.userText")}</div>
          <BusinessCasesTable data={data.Solution.BusinessCases} def={solutionDef}/>
        </div>
      }
      {
        active === "Emmissions" &&
        <div className="results__inner">
          <div className="advanced__inner__text">{t("results.emissionText")}</div>
          <EmissionsTable data={data.Solution.Emissions} def={solutionDef}/>
        </div>
      }
    </div>
  );
}

export default Results;