import React from 'react';
import DanishImg from '../../images/danish.svg';
import EnglishImg from '../../images/english.png';
import { Dropdown } from 'semantic-ui-react'

const languages = [
  {
    key: 'dk',
    text: 'Dansk',
    value: 'dk',
    image: { avatar: true, src: DanishImg },
  },
  {
    key: 'en',
    text: 'English',
    value: 'en',
    image: { avatar: true, src: EnglishImg },
  },

]
const LanguageToggle = ({i18n}) => (
  <Dropdown
    inline
    direction='left'
    options={languages}
    value={i18n.language === "dk" ? languages[0].value : languages[1].value}
    onChange={(e,{ value })=>{
      if(sessionStorage){
        sessionStorage.setItem('lang', value)  
      }
      i18n.changeLanguage(value);
    }}
  />
);

export default LanguageToggle;
