import './project.scss';

import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Input, Button, Form, Container } from 'semantic-ui-react'
import FormField from '../../components/FormField';
import CalculatorField from '../../components/CalculatorField';
import StepProgress from '../../components/StepProgress';
import {flatten} from 'flat';

const ProjectSetup = ({def,data,setData,errors})=>{
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [localData, setLocalData] = useState(flatten(data));
  const propNameKey = i18n.language === "dk" ? "DanishName" : "EnglishName";
  
  const updateLocalProp = (name, val) => {
    setLocalData({...localData, [name]: val});
  };

  return (
    <div className="project">
      <Container>
        <div className="project__head">
          <StepProgress active={1} steps={[{name: t("Navigation.setupStep")},{name: t("Navigation.projectStep")},{name: t("Navigation.resultStep")}]}/>
        </div>      
        <div className="project__body">
          <div className="project__body__text">
            {t('project.introText')} 
          </div>
          <Form className="project__body__form" size="mini">      
            <div className="project__body__form__item">
              <FormField
                horizontal
                name="General.SupplyPrerequisites.HeatType"
                type="select" 
                onchange={updateLocalProp} 
                value={localData["General.SupplyPrerequisites.HeatType"]}                 
                def={def}
                error={!!errors["General.SupplyPrerequisites.HeatType"]} 
                propNameKey={propNameKey} 
              />

            </div>
            <div className="project__body__form__item">
              <FormField
                horizontal
                name="General.SupplyPrerequisites.BoostingMethod"
                type="select" 
                onchange={updateLocalProp} 
                value={localData["General.SupplyPrerequisites.BoostingMethod"]}   
                def={def} 
                error={!!errors["General.SupplyPrerequisites.BoostingMethod"]} 
                propNameKey={propNameKey} 
              /> 
            </div>
            <div className="project__body__form__item">
              <FormField
                name="General.SupplyPrerequisites.HeatpumpType"
                type="select" 
                onchange={updateLocalProp} 
                value={localData["General.SupplyPrerequisites.HeatpumpType"]}             
                def={def} 
                error={!!errors["General.SupplyPrerequisites.HeatpumpType"]} 
                propNameKey={propNameKey} 
                disabled={localData["General.SupplyPrerequisites.BoostingMethod"]==="IndividualHeatpumps"}
              />
            </div>

            <div className="project__body__form__item">
              <CalculatorField 
                helpText={t('project.totalConnectionsHelpText')} 
                error={errors && (!!errors["CorporateFinance.NumberOfResidences"] || !!errors["CorporateFinance.ConnectionPercentage"])} 
                label={t('project.totalConnections')} 
                value={(localData["CorporateFinance.NumberOfResidences"]*localData["CorporateFinance.ConnectionPercentage"]/100).toLocaleString()}>
                <FormField
                  name="CorporateFinance.NumberOfResidences"
                  type="number" 
                  onchange={updateLocalProp} 
                  value={localData["CorporateFinance.NumberOfResidences"]}
                  def={def} 
                  helpText={""}
                  error={!!errors["CorporateFinance.NumberOfResidences"]} 
                  propNameKey={propNameKey} 
                  />
                <FormField
                  name="CorporateFinance.ConnectionPercentage"
                  type="number" 
                  onchange={updateLocalProp} 
                  value={localData["CorporateFinance.ConnectionPercentage"]}
                  def={def} 
                  helpText={""}
                  error={!!errors["CorporateFinance.ConnectionPercentage"]} 
                  propNameKey={propNameKey} 
                  />
              </CalculatorField>
            </div>
            <div className="project__body__form__item">
            </div>
            <div className="project__body__form__item">
              <CalculatorField 
                helpText={t('project.totalHeatingNeedHelpText')} 
                error={!!errors["General.HouseData.ConsumptionMegaWattHours"]} 
                label={t('project.totalHeatingNeed')} 
                value={(localData["General.HouseData.ConsumptionMegaWattHours"]*localData["CorporateFinance.NumberOfResidences"]*localData["CorporateFinance.ConnectionPercentage"]/100).toLocaleString()}>
                <FormField
                  name="General.HouseData.ConsumptionMegaWattHours"
                  type="number" 
                  onchange={updateLocalProp} 
                  value={localData["General.HouseData.ConsumptionMegaWattHours"]}
                  def={def} 
                  helpText={""}
                  error={!!errors["General.HouseData.ConsumptionMegaWattHours"]}
                  propNameKey={propNameKey} 
                />
                <div>
                  <label>{t('project.totalConnections')}</label>
                  <Input value={(localData["CorporateFinance.NumberOfResidences"]*localData["CorporateFinance.ConnectionPercentage"]/100).toLocaleString()} disabled/>
                </div>
              </CalculatorField>
            </div>
            <div className="project__body__form__action">
              <Button color="teal" size="mini" onClick={()=>{setData(localData, ()=>{history.push("/results")})}}>{t('project.save')}</Button>   
            </div>
          </Form>
        </div>      
      </Container>
    </div>
  );
}

export default ProjectSetup;