import './index.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import { parseDefProp, parseUnit } from '../../services/utils';

const OutputTable = ({data, def, title, path, rowsKeys})=>{
  const { i18n } = useTranslation();
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;
  const _rowsKeys = rowsKeys || Object.keys(data);

  return (
    <div className="output-table">
      <Table celled unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='2'>{title}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            _rowsKeys.map((_case,i)=>{
              const props = parseDefProp(def,`${path}.${_case}`);
              return (
                <Table.Row key={`row-${i}`}>
                  <Table.Cell >{`${props.Title && props.Title[languageCode] ? props.Title[languageCode] : props.PropertyName}`}</Table.Cell>
                  <Table.Cell textAlign='right'>{`${data[_case].toLocaleString()} ${parseUnit(props.PropertyUnit, i18n.language)}`}</Table.Cell>
                </Table.Row>
              );
            })
          }
        </Table.Body>
      </Table>
    </div>
  );
}

export default OutputTable;