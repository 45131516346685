import './index.scss';

import baltic from "../../images/IBSR_logo_500px_transp.png";
import eu from "../../images/EUflag_horizontal_450px.png";
import lowtemp from "../../images/LowTemp1000x898px.png";
import skaane from "../../images/rs_webb.jpg";
import hovedstad from "../../images/RegionHovedstaden.png";
import sjaelland from "../../images/RegionSjaelland.jpg";
import oresund from "../../images/Oresund-Kattegat-Skagerrak_rgb_520px.jpg";
import future from "../../images/FUTURE.png";

import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { toast } from 'react-semantic-toasts';
import { Button, Container, Modal, Icon } from 'semantic-ui-react';
import LanguageToggle from '../../components/LanguageToggle';

const Landing = ({setData,setDefaultScenario})=>{
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [openInfo,setOpenInfo] = useState(false);

  const handleChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      try {
        const json = JSON.parse(e.target.result)
        setData(json.Scenario, ()=>{history.push("/results")}, false, json.key)
      } catch (error) {
        toast({
          type: 'error',
          title: 'Error',
          description: error && error.title ? error.title : 'Something went wrong. The selected data file may be corrupt.',
          time: 6000,
          size:"small"
        });
      }
    };
  };

  if(sessionStorage && sessionStorage.getItem('active_scenario')){
    history.push("/results");
  }

  return (
    <Container className="landing">

      <div className="landing__language">
        <LanguageToggle i18n={i18n}/>
      </div>
      
      <h2>{t('landing.paragraph1')}</h2>

      <div className="landing__actions">
        <Button 
          color="teal"
          onClick={()=>{
            if(sessionStorage) sessionStorage.removeItem('active_scenario'); 
            if(sessionStorage) sessionStorage.removeItem('default_scenario'); 
            history.push("/setup"); 
          }} 
          type="button" >
          <i className="share icon"></i>
          {t('landing.start')}
        </Button>

        <input type="file" id="file"  hidden onChange={handleChange}/>
        <Button className="basic secondary" as="label" htmlFor="file" type="button" >
          <i className="upload icon"></i>
          {t('landing.import')}
        </Button>
      </div>

      <p>{t('landing.paragraph2')}</p>
      <p>{t('landing.readMore')}<a href={t('landing.readMoreLink')}>{t('landing.readMoreLinkText')}</a></p>
      <p>{t('landing.contact')} <a href='mailto:thermonetcalc@damani.dk'>thermonetcalc@damani.dk</a></p>

      <div className="landing__support">
        <div>{t('landing.supportedBy')}</div>
        <div className="landing__support__list">
          <div className="landing__support__list__item">
            <div className="landing__support__list__item__name">LowTEMP</div>
            <div className="landing__support__list__item__logo">
              <img src={baltic}/>
              <img src={eu}/>
              <img src={lowtemp}/>
            </div>
          </div>

          <div className="landing__support__list__item">
            <div className="landing__support__list__item__name">FUTURE</div>
            <div className="landing__support__list__item__logo">
              <img src={skaane}/>
              <img src={hovedstad}/>
              <img src={sjaelland}/>
              <img src={oresund}/>
              <img src={future}/>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__footer">
        <p>{t('landing.infoModalText')}<span className="landing__info-link" onClick={() => setOpenInfo(true)}>{t('landing.infoModalTextLink')}</span></p>
      </div>

      <Modal
        onClose={() => setOpenInfo(false)}
        onOpen={() => setOpenInfo(true)}
        open={openInfo}
        closeIcon
        className="landing__info-modal"
        size='large'
        >
          <Modal.Content scrolling>
            <h3>{t('landing.infoModal.section1Head')}</h3>
            <p>{t('landing.infoModal.section1Text')}</p>
            <h3>{t('landing.infoModal.section2Head')}</h3>
            <p>{t('landing.infoModal.section2Text')}</p>
            <h3>{t('landing.infoModal.section3Head')}</h3>
            <p>{t('landing.infoModal.section3Text')}</p>
            <ul>
              <li>{t('landing.infoModal.section3ListItem1')}</li>
              <li>{t('landing.infoModal.section3ListItem2')}</li>
            </ul>
            
          </Modal.Content>
        </Modal>
    
    </Container>
  );
}

export default Landing;
