import './index.scss';
import { Icon } from 'semantic-ui-react';

const StepProgress = ({ steps, active })=>{
  return steps ? (
    <div className={`step-progress`}>
      {
        steps.map((step,i)=>{
          const state = active === i ? "active" : active > i ? "done" : "todo";
          return (
            <div className={`step-progress__step ${state}`} key={`step-${i}`}>
              <div className="step-progress__step__progress">
                {
                  <span className={`step-progress__step__progress__line ${state}`}></span>
                }
                <Icon className={`${state}`} color="teal" size="big" name={active === i ? "dot circle" : active > i ? "check circle" : "circle outline"}/>
              </div>
              <div className="step-progress__step__title">{step.name}</div>
            </div>
          );
        })
      }
      
    </div>
  ): null;
}

export default StepProgress;