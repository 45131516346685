import './setup.scss';

import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Input, Button, Select, Form, Container } from 'semantic-ui-react'
import HelpText from '../../components/HelpText';
import StepProgress from '../../components/StepProgress';


const Setup = ({data,setData,scenarioList,setDefaultScenario})=>{
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [localData, setLocalData] = useState(data);
  const [localScenario, setLocalScenario] = useState();
  const propNameKey = i18n.language === "dk" ? "da" : "en";

  const localizedDescription = localScenario ? (localScenario.Description && localScenario.Description[propNameKey]) ? localScenario.Description[propNameKey] : localScenario.Description : null;

  return (
    <div className="setup">
      <Container>
        <div className="setup__head">
          <StepProgress active={0} steps={[{name: t("Navigation.setupStep")},{name: t("Navigation.projectStep")},{name: t("Navigation.resultStep")}]}/>
        </div>      
        <div className="setup__body">
          <div className="setup__body__text">
            {t('setup.introText')} 
          </div>
          <Form className="setup__body__form" size="mini">      
            <div className="setup__body__form__item">
              <div>{t('setup.name')}</div> 
              <Input name="Configuration" id="Configuration" value={localData.Configuration || ""} onChange={(e)=>setLocalData({...localData, "Configuration": e.target.value})}/>   
            </div>
            <div className="setup__body__form__item">
              <div>{t('setup.region')} <HelpText body={t('setup.regionHelp')} /></div> 
              <Select 
                disabled={!scenarioList || !scenarioList.length} 
                onChange={(e,d)=>{
                  const target = scenarioList.find(s=> s && s.Key === d.value);
                  setLocalScenario(target);
                  setDefaultScenario(target);
                }}
                options={scenarioList.reduce((acc, cur)=>{
                  if(cur.Key !== "Empty") acc.push({key: cur.Key, value: cur.Key, text: cur.Name});
                  return acc;
                },[])}/> 
            </div>
          </Form>  
          {
            !!localizedDescription &&
            <div className="setup__body__form__description">
              {
                localizedDescription
              }
            </div>    
          }
          <div className="setup__body__form__actions">
            <Button 
              disabled={!localScenario} 
              color="teal" 
              size="mini" 
              onClick={()=>{console.log(data, localData); setData(localData, ()=>{history.push("/project")},true)}}>
                {t('setup.save')}
            </Button>   
          </div>
        </div>      
      </Container>
    </div>
  );
}

export default Setup;
