import './index.scss';

import React from 'react';
import { Input, Select } from 'semantic-ui-react'
import { parseDefProp, parseUnit } from '../../services/utils';
import { useTranslation } from 'react-i18next';
import HelpText from "../HelpText";

const FormField = ({name, type, onchange, onblur, def, options, value, className, error, disabled, label, horizontal, helpText})=>{
  const props = parseDefProp(def,name);
  const { i18n } = useTranslation();
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;
  const _helpText = helpText ? helpText : (props.Description && props.Description[languageCode])  ? props.Description[languageCode] : null;
  const _unit = parseUnit(props['PropertyUnit'], i18n.language, "DKK");
  let _options = options || [];

  if(props.Options){  
    _options = Object.keys(props.Options).reduce((acc, cur)=>{
      if(cur !== "Undefined") acc.push({key: cur, value: cur, text: props.Options[cur][languageCode] || cur});
      return acc;
    },[]);
  }

  return type === "select" ? (
    <div className={`form-field ${className} ${horizontal ? 'horizontal' : ''}`}>
      <label htmlFor={name}>{label ? label : (props.Title && props.Title[languageCode]) ? props.Title[languageCode] : ''} {_helpText ? <HelpText body={_helpText}/> : ""}</label> 
      <Select 
        disabled={disabled}
        name={name}
        id={name}
        error={!!error}
        value={value} 
        options={_options}
        onChange={(e,d)=>{ 
          onchange(name, d.value)
        }}
      />  
    </div>
  ) : type === "bool" ? (
    <div className={`form-field ${className} ${horizontal ? 'horizontal' : ''}`}>
      <label htmlFor={name}>{label ? label : (props.Title && props.Title[languageCode]) ? props.Title[languageCode] : ''} {_helpText ? <HelpText body={_helpText}/> : ""}</label> 
      <Select 
        disabled={disabled}
        name={name}
        id={name}
        error={!!error}
        value={value} 
        options={[{key: 'yes', value: true, text: languageCode === 'da' ? 'Ja' : 'Yes'}, {key: 'no', value: false, text: languageCode === 'da' ? 'Nej' : 'No'}]}
        onChange={(e,d)=>{ onchange(name, d.value)}}
      />  
    </div>
  ) : (
    <div className={`form-field ${className} ${horizontal ? 'horizontal' : ''}`}>
      <label htmlFor={name}>{label ? label : (props.Title && props.Title[languageCode]) ? props.Title[languageCode] : ''} {_helpText ? <HelpText body={_helpText}/> : ""}</label> 
      <Input 
        disabled={disabled}
        name={name}
        id={name}
        error={!!error}
        type={type || "text"}
        label={_unit}
        labelPosition={!!_unit ? 'right' : null}
        value={value || ""} 
        onBlur={(e)=>{if(onblur) onblur(name, type === "number" ? parseFloat(e.target.value) : e.target.value)}}
        onChange={(e)=>onchange(name, e.target.value)}
      />  
    </div>
  );
}

export default FormField;