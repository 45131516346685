export const parseUnit = (unit,languange, currency = "DKK") => {
  let cur = currency;
  
  if(sessionStorage && sessionStorage.getItem('default_scenario')) cur = JSON.parse(sessionStorage.getItem('default_scenario')).Currency;

  if(!unit){
    return '';
  } else if(languange === 'en') {
      return unit.replace('(ratio)', '%').replace('yr', 'year').replace('Currency', cur).replace('hrs', 'hours');
  }
  return unit.replace('(ratio)', '%').replace('yr', 'år').replace('Currency', cur).replace('units', 'styk').replace('unit', 'styk').replace('hrs', 'timer');
}

export const parseDefProp = (def,name) => {
  const path = name.split(".");
  let props = def || {};
  for(let i = 0; i < path.length; i++){
    if(i === (path.length - 1)){
      if(!props['Properties'] || !props['Properties'][path[i]]){
        return {};
      } else {
        props = props['Properties'][path[i]];
      }
    } else {
      if(!props['Sections'] || !props['Sections'][path[i]]){
        return {};
      } else {
        props = props['Sections'][path[i]];
      }
    }
  }
  return props;
}

export const updatedDataObj = (value,name,obj) => {
  const _path = name.split(".");
  let _obj = obj || {};
  
  if(_path.length === 1){
    _obj[_path[0]] = value;
  } else if(_path.length === 2 && _obj[_path[0]]){
    _obj[_path[0]][_path[1]] = value;
  } else if(_path.length === 3 && _obj[_path[0]] && _obj[_path[0]][_path[1]]){
    _obj[_path[0]][_path[1]][_path[2]] = value;
  } else if(_path.length === 4 && _obj[_path[0]] && _obj[_path[0]][_path[1]] && _obj[_path[0]][_path[1]][_path[2]]){
    _obj[_path[0]][_path[1]][_path[2]][_path[3]] = value;
  }

  return _obj;
}

export const getNestedData = (name,obj) => {
  const _path = name.split(".");
  let value = obj;
  
  for(let i = 0; i < _path.length; i++){
    if(i === (_path.length - 1)){
      if(value[_path[i]]){ 
        value = value[_path[i]];
      } else return null;
    } else {
      if(value[_path[i]]){ 
        value = value[_path[i]];
      } else return null;    }
  }

  return value;
}

export const round = (target, places) => {
  return +(Math.round(target + "e+" + places)  + "e-" + places);
}