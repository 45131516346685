import './index.scss';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { parseDefProp, parseUnit, getNestedData } from '../../services/utils';

const StackedBarChart = ({data, def})=>{
  const { t, i18n } = useTranslation();
  let bar = null;
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;

  const colors = [
    "#c9f065",
    "#df51b4",
    "#a80047",
    "#ba77ef",
    "#fa7940",
    "#ffdc52",
    "#0fffe5",
    "#652b87",
    "#6d79f3",
    "#09952d",
    "#01c1ff",
    "#b0689d",
    "#01c38c",
    "#40ba9f",
    "#8467a9",
    "#e38721",
    "#6c6300",
    "#f2a7ff",
    "#ff78a9",
    "#69ffa0",
    "#ff72c1",
    "#9b0008",
    "#979d00",
    "#d22a45",
    "#d3ff84",
    "#ac37a5"
  ];

  const items = [
    'YearlyCosts.YearlyCostsBranchlinePipe',
    'YearlyCosts.YearlyCostsCentralHeatPump',
    'YearlyCosts.YearlyCostsDistributionNetLarge',
    'YearlyCosts.YearlyCostsDistributionNetSmall',
    'YearlyCosts.YearlyCostsElectricMeters',
    'YearlyCosts.YearlyCostsElectricity',
    'YearlyCosts.YearlyCostsGeothermalDrillings',
    'YearlyCosts.YearlyCostsHeatPumpInstallation',
    'YearlyCosts.YearlyCostsHeatPumps',
    'YearlyCosts.YearlyCostsHeatPurchase',
    'YearlyCosts.YearlyCostsPipeworkAccesshole',
    'YearlyCosts.YearlyCostsPipeworkDrilling',
    'YearlyCosts.YearlyCostsService',
    'YearlyCosts.YearlyCostsTraditionalDistributionGrid',
    'YearlyCosts.YearlyCostsTransmissionLineAccessHoles',
    'YearlyCosts.YearlyCostsTransmissionLineDrilling',
    'YearlyCosts.YearlyCostsTransmissionLinePipe'
  ];
  
  const items2 = [
    'YearlyCosts.YearlyIncomeBranchlineContributionMeter',
    'YearlyCosts.YearlyIncomeBranchlineContributionStart',
    'YearlyCosts.YearlyIncomeExtraordinaryContribution',
    'YearlyCosts.YearlyIncomeFixedHeatingPrice',
    'YearlyCosts.YearlyIncomeInvestmentContribution',
    'YearlyCosts.YearlyIncomeMeterRental',
    'YearlyCosts.YearlyIncomeVariableHeatingPrice',
  ];
  
  const _data = {
    labels: [`${t('YearlyCosts')} (${parseUnit('Currency', i18n.language)}/${parseUnit('yr', i18n.language)})  /  ${t('YearlyIncome')} (${parseUnit('Currency', i18n.language)}/${parseUnit('yr', i18n.language)})`],
    datasets: [...items.map((item, i)=>(
      {
        label:  parseDefProp(def, item).Title ? parseDefProp(def, item).Title[languageCode] : '',
        maxBarThickness: 40,
        minBarThickness: 40,
        data: [getNestedData(item,data)],
        backgroundColor: colors[i],
        stack: 'Stack 0',
      }
    )),
    ...items2.map((item, i)=>(
      {
        label:  parseDefProp(def, item).Title ? parseDefProp(def, item).Title[languageCode] : '',
        maxBarThickness: 40,
        minBarThickness: 40,
        data: [getNestedData(item,data)],
        backgroundColor: colors[items.length+i],
        stack: 'Stack 1',
      }
    ))
    ]
  }
  
  const _options = {
    responsive:true,
    maintainAspectRatio: false, 
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        title: item => {
          return (_data.datasets[item[0].datasetIndex].label)
        },
        label: (item) => {
          return (` ${item.yLabel.toLocaleString()} ${parseUnit('Currency', i18n.language)}`)
        }
      },
    },
    scales: {
      label: "<%= ' ' + value%>",
      xAxes: [{
          stacked: true
      }],
      yAxes: [{
          stacked: true,
          ticks: {
            userCallback: (item) => `${item.toLocaleString()} ${parseUnit('Currency', i18n.language)}`,
            beginAtZero: true,
          },
      }]
    }
  }
  
  return (
    <div className={`stacked-bar-chart`}>
      <div className="stacked-bar-chart__chart">
        <Bar data={_data} options={_options} ref={ref => (bar = ref)} />
      </div>
    </div>
  );
}

export default StackedBarChart;