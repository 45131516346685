import './index.scss';

import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react'


const FormSection = ({children, className="", accordion = false, accordionTitle, noBorder})=>{
  const [showChildren, setShowChildren] = useState(!accordion);
  
  return (
    <div className={`form-section ${className || ''} ${noBorder ? 'no-border' : ''}`}>
      {
        !!accordion &&
        <Accordion >
          <Accordion.Title
            active={showChildren}
            index={0}
            onClick={()=>{setShowChildren(!showChildren)}}
          >
            <Icon name='dropdown' />
            {accordionTitle}
          </Accordion.Title>
          <Accordion.Content active={showChildren}>
            {children}
          </Accordion.Content>
        </Accordion>
      }
      {
        !accordion &&
        children
      }
    </div>
  );
}

export default FormSection;