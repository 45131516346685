import i18n from "i18next";
import en from "./i18n/en.json";
import dk from "./i18n/dk.json";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: en
  },
  dk: {
    translation: dk
  }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: "dk",
    keySeparator: ".", 
    interpolation: {
      escapeValue: false
    },
    transEmptyNodeValue: '', // what to return for empty Trans
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
  });

  export default i18n;