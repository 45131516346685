import './index.scss';
import { useTranslation } from 'react-i18next';
import { round } from '../../services/utils';

const ThermoDynamicGraph = ({data})=>{
  const { t } = useTranslation();
  const pUnit = " W";
  const copUnit = " W/W";
  const p2Unit = " Wh";
  const tUnit = " °C";
  const lUnit = " m";
  const content = {
    outOfHouses: {
      title: t('thermodynamicsGraph.outOfHouses'),
      value_t: data.Scenario.Thermodynamics.Transmission.TemperatureComingOutOfHouses+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerOutOfHouses, 0)+pUnit,
    }, 
    outOfCity: {
      title: t('thermodynamicsGraph.outOfCity'),
      value_t: round(data.Solution.Thermodynamics.Transmission.TemperatureFlowOutOfTheCity, 0)+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerOutOfCity, 0)+pUnit,
    }, 
    returnToPump: {
      title: t('thermodynamicsGraph.returnToPump'),
      value_t: round(data.Solution.Thermodynamics.Transmission.TemperatureReturnToHeatpump, 0)+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerReturnToHeatpump, 0)+pUnit,
    }, 
    returnFromPump: {
      title: t('thermodynamicsGraph.returnFromPump'),
      value_t: data.Scenario.Thermodynamics.Transmission.TemperatureReturnFromHeatpump+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerReturnOutOfHeatpump, 0)+pUnit,
    }, 
    returnToSupplier: {
      title: t('thermodynamicsGraph.returnToSupplier'),
      value_t: round(data.Solution.Thermodynamics.Transmission.TemperatureReturnToPlant, 0)+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerReturnToPlant, 0)+pUnit,
    }, 
    flowFromSupplier: {
      title: t('thermodynamicsGraph.flowFromSupplier'),
      value_t: data.Scenario.Thermodynamics.Transmission.TemperatureComingOutOfPlant+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerOutOfPlant, 0)+pUnit,
    }, 
    flowToPump: {
      title: t('thermodynamicsGraph.flowToPump'),
      value_t: round(data.Solution.Thermodynamics.Transmission.TemperatureFlowIntoHeatpump, 0)+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerFlowIntoHeatpump, 0)+pUnit,
    }, 
    flowFromPump: {
      title: t('thermodynamicsGraph.flowFromPump'),
      value_t: data.Scenario.Thermodynamics.Transmission.TemperatureFlowFromHeatPump+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerFlowOutOfHeatpump, 0)+pUnit,
    }, 
    flowToCity: {
      title: t('thermodynamicsGraph.flowToCity'),
      value_t: round(data.Solution.Thermodynamics.Transmission.TemperatureIntoCity, 0)+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerIntoCity, 0)+pUnit,
    }, 
    flowToHouses: {
      title: t('thermodynamicsGraph.flowToHouses'),
      value_t: round(data.Solution.Thermodynamics.Transmission.TemperatureIntoHouses, 0)+tUnit,
      value_p: round(data.Solution.Thermodynamics.Transmission.PowerIntoHouses, 0)+pUnit,
    }, 
    lengthISO: {
      title: t('thermodynamicsGraph.lengthISO'),
      value_l: round(data.Solution.Thermodynamics.Transmission.PipeLengthTransmissionIso, 0)+lUnit,
    }, 
    lengthPEX: {
      title: t('thermodynamicsGraph.lengthPEX'),
      value_l: data.Scenario.Thermodynamics.Transmission.LengthPipePex+lUnit,
    }, 
    lengthTotal: {
      title: t('thermodynamicsGraph.lengthTotal'),
      value_l: data.Scenario.Thermodynamics.Transmission.LengthPipeTotal+lUnit,
    }, 
    powerHeatPump: {
      title: t('thermodynamicsGraph.powerHeatPump'),
      value_p: round(data.Solution.Thermodynamics.Transmission.HeatpumpUtilization, 0)+pUnit
    }, 
    electricityConsumption: {
      title: t('thermodynamicsGraph.electricityConsumption'),
      value: round(data.Solution.Thermodynamics.Transmission.ElectricityConsumptionAtPeakCentralHeatpump, 0)+pUnit
    }, 
    copFactor: {
      title: t('thermodynamicsGraph.copFactor'),
      value: round(data.Solution.Thermodynamics.Transmission.CopFactor, 0)+copUnit
    }, 
    powerCityMax: {
      title: t('thermodynamicsGraph.powerCityMax'),
      subtitle: t('thermodynamicsGraph.dimensioning'),
      subsubtitle: t('thermodynamicsGraph.energi'),
      value_p: round(data.Solution.Thermodynamics.Consumer.HeatingNeedPeak, 0)+pUnit,
      value_p2: round(data.Solution.Thermodynamics.Consumer.HeatingNeedHousesGrossPeakInclDistributionLoss, 0)+pUnit,
    }, 
    powerCityTotal: {
      title: t('thermodynamicsGraph.powerCityTotal'),
      subtitle: t('thermodynamicsGraph.powerCityYearly'),
      subsubtitle: t('thermodynamicsGraph.energi'),
      value_p: round(data.Solution.Thermodynamics.Consumer.HeatingNeedNetPerYear, 0)+p2Unit,
      value_p2: round(data.Solution.Thermodynamics.Consumer.HeatingNeedCityGrossAllYear, 0)+p2Unit,
    }          
  };

  return (
    <div className="thermo">
        <svg width="941px" height="467px" viewBox="0 0 941 467" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <rect id="path-1" x="0" y="0" width="92" height="46"></rect>
        <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="92" height="46" fill="white">
            <use xlinkHref="#path-1"></use>
        </mask>
        <path d="M0.516363636,0.5 L283.483636,1.5" id="path-3"></path>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-4">
            <stop stopColor="#FFFFFF" stopOpacity="0.5" offset="0%"></stop>
            <stop stopColor="#000000" stopOpacity="0.5" offset="100%"></stop>
        </linearGradient>
        <path d="M0.516363636,18.5 L283.483636,19.5" id="path-5"></path>
        <path d="M0.495327103,0.5 L212,1" id="path-6"></path>
        <path d="M0.495327103,20.5 L212,21" id="path-7"></path>
        <rect id="path-8" x="0" y="2" width="21" height="11"></rect>
        <mask id="mask-9" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="21" height="11" fill="white">
            <use xlinkHref="#path-8"></use>
        </mask>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Thermo-Copy">
            <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="941" height="467"></rect>
            <g id="flow" transform="translate(34.000000, 201.000000)">
                <g id="in-from-heatpump" transform="translate(328.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="137" height="61" rx="3"></rect>
                    <text id="Flow-ud-af-varmepump" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="9" y="17">{content.flowFromPump.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="20" y="36">P = {content.flowFromPump.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="20" y="50">T = {content.flowFromPump.value_t}</tspan>
                    </text>
                    <rect id="Rectangle" fill="#FFCA63" x="9" y="42" width="9" height="9"></rect>
                </g>
                <g id="in-to-heatpump-" transform="translate(518.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="140" height="61" rx="3"></rect>
                    <text id="Flow-ind-i-varmepump" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.flowToPump.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="35">P = {content.flowToPump.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="49">T = {content.flowToPump.value_t}</tspan>
                    </text>
                </g>
                <g id="in-supply" transform="translate(732.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="140" height="61" rx="3"></rect>
                    <text id="Ud-af-fabrik" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.flowFromSupplier.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="19" y="35">P = {content.flowFromSupplier.value_p}</tspan>
                    </text>
                    <rect id="Rectangle" fill="#FFCA63" x="8" y="41" width="9" height="9"></rect>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="19" y="49">T = {content.flowFromSupplier.value_t}</tspan>
                    </text>
                </g>
                <g id="in-city" transform="translate(184.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="100" height="61" rx="3"></rect>
                    <text id="Ind-i-byen" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.flowToCity.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="35">P = {content.flowToCity.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="49">T = {content.flowToCity.value_t}</tspan>
                    </text>
                </g>
                <g id="in-houses">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="128" height="61" rx="3"></rect>
                    <text id="Ind-til-bopæle" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.flowToHouses.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="35">P = {content.flowToHouses.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="49">T = {content.flowToHouses.value_t}</tspan>
                    </text>
                </g>
            </g>
            <g id="graph" transform="translate(34.000000, 125.000000)">
                <g id="distribution-net" transform="translate(112.000000, 6.000000)">
                    <text id="Distributionsnet" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8.16748047" y="25">{t('thermodynamicsGraph.distributionNet')}</tspan>
                    </text>
                    <use id="Rectangle" stroke="#979797" mask="url(#mask-2)" strokeWidth="2" strokeDasharray="3" xlinkHref="#path-1"></use>
                </g>
                <g id="pipes1" transform="translate(512.000000, 20.000000)">
                    <g id="Line">
                        <use stroke="#000000" strokeWidth="10" xlinkHref="#path-3"></use>
                        <use stroke="#62E0EC" strokeWidth="8" xlinkHref="#path-3"></use>
                    </g>
                    <g id="Line-Copy-3">
                        <use fill="#D8D8D8" fillRule="evenodd" xlinkHref="#path-5"></use>
                        <use stroke="#000000" strokeWidth="10" fill="url(#linearGradient-4)" fillRule="evenodd" xlinkHref="#path-5"></use>
                        <use stroke="#FF0000" strokeWidth="8" xlinkHref="#path-5"></use>
                    </g>
                </g>
                <g id="pipes2" transform="translate(211.000000, 19.000000)">
                    <g id="Line-Copy">
                        <use stroke="#000000" strokeWidth="15" xlinkHref="#path-6"></use>
                        <use stroke="#FFFFFF" strokeWidth="13" xlinkHref="#path-6"></use>
                        <use stroke="#000000" strokeWidth="10" xlinkHref="#path-6"></use>
                        <use stroke="#62E0EC" strokeWidth="8" xlinkHref="#path-6"></use>
                    </g>
                    <g id="Line-Copy-2">
                        <use stroke="#000000" strokeWidth="15" xlinkHref="#path-7"></use>
                        <use stroke="#FFFFFF" strokeWidth="13" xlinkHref="#path-7"></use>
                        <use stroke="#000000" strokeWidth="10" xlinkHref="#path-7"></use>
                        <use stroke="#FF0000" strokeWidth="8" xlinkHref="#path-7"></use>
                    </g>
                </g>
                <g id="pump" transform="translate(428.500000, 0.000000)">
                    <polygon id="Triangle" fill="#000000" points="39 0 56.5 8 21.5 8"></polygon>
                    <polygon id="Triangle-Copy" fill="#000000" transform="translate(39.000000, 55.000000) rotate(180.000000) translate(-39.000000, -55.000000) " points="39 51 56.5 59 21.5 59"></polygon>
                    <polygon id="Triangle-Copy-2" fill="#000000" transform="translate(72.000000, 30.500000) rotate(90.000000) translate(-72.000000, -30.500000) " points="72 25 85.5 36 58.5 36"></polygon>
                    <polygon id="Triangle-Copy-3" fill="#000000" transform="translate(6.000000, 29.500000) rotate(-90.000000) translate(-6.000000, -29.500000) " points="6 24 19.5 35 -7.5 35"></polygon>
                    <rect id="Rectangle" fill="#000000" x="13.5" y="10" width="52" height="39" rx="5"></rect>
                    <ellipse id="Oval" fill="#FFFFFF" cx="39" cy="30" rx="22.5" ry="16"></ellipse>
                    <polyline id="Path-3" stroke="#000000" strokeWidth="3" points="16.5 30.2467329 29.6893539 29.77033 39.3629032 23 39.3629032 36 48.1261725 29.5 61.5 29.5"></polyline>
                </g>
                <g id="consumer" transform="translate(0.000000, 11.000000)" fill="#000000">
                    <g id="church-copy">
                        <rect id="Rectangle" x="0" y="26.1646235" width="31.9119351" height="9.83537653"></rect>
                        <polygon id="Triangle" points="15.9559676 17.9054291 31.9119351 26.2276708 0 26.2276708"></polygon>
                        <rect id="Rectangle" x="31.9119351" y="33.0998249" width="5.02317497" height="2.90017513"></rect>
                        <polygon id="Triangle" points="43.9084589 0 51 17.9054291 36.8169177 17.9054291"></polygon>
                        <rect id="Rectangle" x="36.8169177" y="17.8423818" width="14.1830823" height="18.1576182"></rect>
                    </g>
                    <g id="houses-copy" transform="translate(54.000000, 22.000000)">
                        <rect id="Rectangle" x="0" y="3.84186047" width="32.2758621" height="10.1581395"></rect>
                        <rect id="Rectangle" x="32.1563218" y="12.3069767" width="3.70574713" height="1.69302326"></rect>
                        <polygon id="Triangle" points="43.9310345 2.86511628 52 5.9255814 35.862069 5.9255814"></polygon>
                        <rect id="Rectangle" x="35.862069" y="5.86046512" width="16.137931" height="8.13953488"></rect>
                        <polygon id="Path-2" points="32.2758621 3.90697674 27.3747126 0.0144985465 4.90114943 0.0144985465 0 3.90697674"></polygon>
                    </g>
                </g>
                <g id="fabrik-copy" transform="translate(803.000000, 0.000000)" fill="#000000">
                    <rect id="Rectangle" x="52" y="0" width="17" height="24"></rect>
                    <rect id="Rectangle" x="0" y="23" width="69" height="24"></rect>
                    <polygon id="Path" points="0 12 0 24 17 24"></polygon>
                    <polygon id="Path-Copy" points="17 12 17 24 34 24"></polygon>
                    <polygon id="Path-Copy-2" points="35 12 35 24 52 24"></polygon>
                </g>
            </g>
            <g id="returnflow" transform="translate(34.000000, 31.000000)">
                <g id="out-houses">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.49970476" x="0.5" y="0.5" width="125" height="61" rx="3"></rect>
                    <text id="Ud-af-bopæle" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.outOfHouses.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="20" y="35">P = {content.outOfHouses.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="20" y="50">T = {content.outOfHouses.value_t}</tspan>
                    </text>
                    <rect id="Rectangle" fill="#FFCA63" x="8" y="42" width="9" height="9"></rect>
                </g>
                <g id="out-city" transform="translate(184.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="100" height="61" rx="3"></rect>
                    <text id="Ud-af-byen" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.outOfCity.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="35">P = {content.outOfCity.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="49">T = {content.outOfCity.value_t}</tspan>
                    </text>
                </g>
                <g id="out-to-heatpump" transform="translate(328.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="126" height="61" rx="3"></rect>
                    <text id="Retur-til-varmepumpe" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.returnToPump.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="35">P = {content.returnToPump.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="49">T = {content.returnToPump.value_t}</tspan>
                    </text>
                </g>
                <g id="out-from-heatpump" transform="translate(518.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="140" height="61" rx="3"></rect>
                    <text id="Retur-ud-af-varmepum" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.returnFromPump.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="20" y="36">P = {content.returnFromPump.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="20" y="50">T = {content.returnFromPump.value_t}</tspan>
                    </text>
                    <rect id="Rectangle" fill="#FFCA63" x="8" y="42" width="9" height="9"></rect>
                </g>
                <g id="out-supply" transform="translate(732.000000, 0.000000)">
                    <rect id="Rectangle" stroke="#D4D4D4" opacity="0.501067406" x="0.5" y="0.5" width="140" height="61" rx="3"></rect>
                    <text id="Retur-til-fabrik" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                        <tspan x="8" y="17">{content.returnToSupplier.title}</tspan>
                    </text>
                    <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="35">P = {content.returnToSupplier.value_p}</tspan>
                    </text>
                    <text id="T-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                        <tspan x="8" y="49">T = {content.returnToSupplier.value_t}</tspan>
                    </text>
                </g>
            </g>
            <g id="iso-length" transform="translate(239.000000, 110.000000)" fill="#000000" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold">
                <text id="Længde-af-ISO-rør:">
                    <tspan x="8" y="17">{content.lengthISO.title}: {content.lengthISO.value_l}</tspan>
                </text>
            </g>
            <g id="pex-length-" transform="translate(559.000000, 119.000000)" fill="#000000" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold">
                <text id="Længde-af-PEX-rør:">
                    <tspan x="0" y="10">{content.lengthPEX.title}: {content.lengthPEX.value_l}</tspan>
                </text>
            </g>
            <g id="power-heatpump" transform="translate(387.000000, 341.000000)">
                <rect id="Rectangle" stroke="#D4D4D4" opacity="0.49791061" x="0.5" y="0.5" width="154" height="93" rx="3"></rect>
                <text id="Udnyttet-af-varmepum" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="16">{content.powerHeatPump.title}</tspan>
                </text>
                <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="27">P = {content.powerHeatPump.value_p}</tspan>
                </text>
                <text id="COP-faktor" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="45">{content.electricityConsumption.title}</tspan>
                </text>
                <text id="4.37-W/W" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="56">{content.electricityConsumption.value}</tspan>
                </text>
                <text id="Electricity-Consumpt" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="74">{content.copFactor.title}</tspan>
                </text>
                <text id="9140829.86-W" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="86">{content.copFactor.value}</tspan>
                </text>
            </g>
            <g id="power-city-max" transform="translate(34.000000, 339.000000)">
                <rect id="Rectangle" stroke="#D4D4D4" opacity="0.49791061" x="0.5" y="0.5" width="161" height="96" rx="3"></rect>
                <text id="Afsat-til-byen-(max)" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="16">{content.powerCityMax.title}</tspan>
                </text>
                <text id="Spidsdimensionering" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="58">{content.powerCityMax.subtitle}</tspan>
                </text>
                <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="18" y="34">P = {content.powerCityMax.value_p}</tspan>
                </text>
                <rect id="Rectangle" fill="#FFCA63" x="7" y="26" width="9" height="9"></rect>
                <text id="P-=-Copy" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="88">P = {content.powerCityMax.value_p2}</tspan>
                </text>
                <text id="Energi-inkl.-distr." fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="70">{content.powerCityTotal.subsubtitle}</tspan>
                </text>
            </g>
            <g id="power-city-total" transform="translate(207.000000, 339.000000)">
                <rect id="Rectangle" stroke="#D4D4D4" opacity="0.49791061" x="0.5" y="0.5" width="161" height="96" rx="3"></rect>
                <text id="Afsat-til-byen-i-alt" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="16">{content.powerCityTotal.title}</tspan>
                </text>
                <text id="Årligt-varmebehov" fontFamily="Helvetica-Bold, Helvetica" fontSize="10" fontWeight="bold" fill="#000000">
                    <tspan x="8" y="58">{content.powerCityTotal.subtitle}</tspan>
                </text>
                <text id="P-=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="34">P = {content.powerCityTotal.value_p}</tspan>
                </text>
                <text id="P-=-Copy-2" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="88">P = {content.powerCityTotal.value_p2}</tspan>
                </text>
                <text id="Energi-inkl.-distr." fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="8" y="70">{content.powerCityTotal.subsubtitle}</tspan>
                </text>
            </g>
            <g id="noDistNetText" transform="translate(557.000000, 425.000000)">
                <text id="=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="32" y="10">= {t('thermodynamicsGraph.noDistributionNet')}</tspan>
                </text>
                <use id="Rectangle" stroke="#979797" mask="url(#mask-9)" strokeWidth="2" strokeDasharray="3" xlinkHref="#path-8"></use>
            </g>
            <g id="editable" transform="translate(569.000000, 404.000000)">
                <rect id="Rectangle" fill="#FFCA63" x="0" y="3" width="9" height="9"></rect>
                <text id="=" fontFamily="Helvetica" fontSize="10" fontWeight="normal" fill="#000000">
                    <tspan x="20" y="10">= {t('thermodynamicsGraph.editable')}</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
    </div>
  );
}

export default ThermoDynamicGraph;