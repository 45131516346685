import './index.scss';

import React from 'react';
import { Input, Icon, Popup } from 'semantic-ui-react'
import HelpText from "../HelpText";

const CalculatorField = ({value, label, name, children, className, error, helpText})=>{
  return (
    <div className={`calculator-field ${className}`}>
      <label htmlFor={name}>{label} {helpText ? <HelpText body={helpText}/> : ""}</label> 
      <Input 
        name={name} 
        id={name} 
        error={!!error}
        value={value}
        disabled
        icon={          
          <Popup
            on='click'
            pinned
            size="mini"
            trigger={<Icon name='calculator' inverted circular link />}
          >
            {children}
          </Popup>
        }
      />   
      
    </div>
  );
}

export default CalculatorField;
