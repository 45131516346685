import './index.scss';
import options from './options.json';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import { Select, Dropdown, Dimmer, Loader, Button } from 'semantic-ui-react';
import { getSensitivityAnalysis } from "../../services/requests";
import { toast } from 'react-semantic-toasts';
import { parseDefProp, getNestedData, parseUnit } from '../../services/utils';

const SensitivityAnalysis = ({scenario, def, defaultScenario})=>{
  const { i18n } = useTranslation();
  const propNameKey = i18n.language === "dk" ? "da" : "en";

  const [target, setTarget] = useState(options.groups[0].options[0]);
  const [dataset, setDataset] = useState({});
  const [factors, setFactors] = useState([-30,-20,-10,0,10,20,30]);
  const [factorOpt, setFactorOpt] = useState(30);
  const [isLoading, setIsLoading] = useState(true);

  const getData = (_target, _factors)=>{
    setIsLoading(true);
    
    getSensitivityAnalysis(defaultScenario.Key, _target, scenario, _factors || factors).then(res=>{
      const keys = Object.keys(res.Solutions).sort((a, b)=>(a - b));
      setDataset({
        labels: keys.map(key=>{
          const val = getNestedData(_target, scenario);
          return !val ? `${key}%`: (`${val + (val * parseInt(key) / 100)}${parseUnit(parseDefProp(def,_target).PropertyUnit, i18n.language)}`);
        }),
        datasets: [
          {
            label: "NPV",
            data: keys.map(key=>(res.Solutions[key].NPV)),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
        ],
      })
      setIsLoading(false);
    }, (err)=>{
      setIsLoading(false);
      toast({
        type: 'error',
        title: 'Error',
        description: err && err.title ? err.title : 'Something went wrong',
        time: 6000,
        size:"small"
      });
    })
  };

  useEffect(()=>{
    getData(target)
  }, []);
  
  const _options = {
    scales: {
      yAxes: [
        {
          ticks: {
            userCallback: (item) => `${item.toLocaleString()}`,
            beginAtZero: true,
          },
        },
      ]
    },
  }
  
  return (
    <div className={`sensitivity-analysis ${isLoading ? 'loading' : ''}`}>
      <div className="sensitivity-analysis__controls">

          <Button.Group basic color='teal' className="sensitivity-analysis__controls__target">

          <Dropdown
            text='Filter'
            scrolling
            text={parseDefProp(def,target) && parseDefProp(def,target).Title ? parseDefProp(def,target).Title[propNameKey] : target}
            button
            >
            <Dropdown.Menu>
              {
                options &&
                options.groups &&
                options.groups.map((group,i)=>{
                  return (
                    <React.Fragment key={`group-${i}`}>
                      <Dropdown.Header icon="angle down" content={group.Title[propNameKey]} />
                      <Dropdown.Divider />
                        {
                          group.options.map((opt,i)=>{
                            const props = parseDefProp(def,opt);
                            return (
                              <Dropdown.Item 
                                onClick={()=>{ 
                                  setTarget(opt);
                                  getData(opt);
                                }}
                                key={`opt-${i}`} 
                                text={props && props.Title ? props.Title[propNameKey] : opt} />
                            )
                          })
                        }
                      <Dropdown.Divider />
                    </React.Fragment>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>   
          </Button.Group>     
        <Select 
          options={[{key: "75", value: 75, text: '+/- 75%'},{key: "50", value: 50, text: '+/- 50%'},{key: "30", value: 30, text: '+/- 30%'}]} 
          value={factorOpt} 
          onChange={(e,d)=>{ 
            const _factors = d.value === 75 ? [-75,-50,-25,0,25,50,75] : d.value === 50 ? [-50, -40,-30,-20,-10,0,10,20,30,40,50] : [-30,-20,-10,0,10,20,30];
            setFactorOpt(d.value);
            setFactors(_factors);
            getData(target,_factors);
          }}
          size="mini"/>
      </div>
      <div className="sensitivity-analysis__description">{(parseDefProp(def,target).Description && parseDefProp(def,target).Description[propNameKey]) ? parseDefProp(def,target).Description[propNameKey] : ''}</div>
      <div className="sensitivity-analysis__inner">   
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Line data={dataset} options={_options} />
      </div>
    </div>
  );
}

export default SensitivityAnalysis;