import 'semantic-ui-css/semantic.min.css'
import './App.scss';
import React, { useState, useEffect } from 'react';
import {
  MemoryRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {updateScenario, getDefinitions, getSolutionDefinitions, getScenarioList} from './services/requests';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import { Modal, Button } from 'semantic-ui-react'
import DownloadLink from "./components/DownloadLink";
import Landing from "./pages/Landing";
import Setup from "./pages/Setup";
import Results from "./pages/Results";
import Advanced from "./pages/Advanced";
import ProjectSetup from "./pages/ProjectSetup";
import {unflatten} from 'flat';
import NavBar from './components/NavBar';

function App() {
  const [dataset, setDataset] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [definitions, setDefinitions] = useState({});
  const [solutionDefinitions, setSolutionDefinitions] = useState({});
  const [scenarios, setScenarios] = useState([]);
  const [defaultScenario, setDefaultScenario] = useState({});
  const [openExport, setOpenExport] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  }

  const recalculate = (data, callback, noStorage, defaultScenarioOverwrite) => {

    if(!data && !callback && !noStorage){
      setDataset({});
      return
    }

    if(defaultScenarioOverwrite){
      setDefaultScenario(defaultScenarioOverwrite);
      if(sessionStorage ) sessionStorage.setItem('default_scenario',JSON.stringify(defaultScenarioOverwrite));
    }

    setIsLoading(true);
    let unflattened = null;

    // fixes flattening issues
    const iso = data["Thermodynamics.PipeSpecifications.ISO"];
    const pex = data["Thermodynamics.PipeSpecifications.PEX"];
    unflattened = unflatten(data,{ object: true });
    if(unflattened["Thermodynamics"] && unflattened["Thermodynamics"]["PipeSpecifications"] && unflattened["Thermodynamics"]["PipeSpecifications"]["ISO"] ){
      unflattened["Thermodynamics"]["PipeSpecifications"]["ISO"] = iso;
    }
    if(unflattened["Thermodynamics"] && unflattened["Thermodynamics"]["PipeSpecifications"] && unflattened["Thermodynamics"]["PipeSpecifications"]["PEX"] ){
      unflattened["Thermodynamics"]["PipeSpecifications"]["PEX"] = pex;
    }
    
    // do calculations through api
    const key = defaultScenarioOverwrite && defaultScenarioOverwrite.Key ? defaultScenarioOverwrite.Key : defaultScenario.Key;
    updateScenario(key, unflattened).then((res)=>{
      if(res && res.ValidationResult){
        setIsLoading(false);
        if(res.ValidationResult.Valid){
          //everything went well
          console.log("scenario/solution",res);

          if(sessionStorage && !noStorage){
            console.log("adds scenario to session storage");
            sessionStorage.setItem('active_scenario', JSON.stringify(res))  
          }

          setDataset(res);
          setErrors({});
          if(callback) {
            callback(this);
          }
        } else {
          toast({
            type: 'error',
            title: 'Error',
            description: res && res.title ? res.title : 'Something went wrong',
            time: 6000,
            size:"small"
          });
        }
      } else if(res.errors){
        setIsLoading(false);
        toast({
          type: 'error',
          title: 'Error',
          description: res && res.title ? res.title : 'Something went wrong',
          time: 6000,
          size:"small"
        });
        setErrors(res.errors);
      }
    });
  }

  useEffect(()=>{
    getScenarioList().then((result) => {
      console.log("Scenario list",result);
      setScenarios(result);
      },
      (error) => {
        toast({
          type: 'error',
          title: 'Error',
          description: error && error.title ? error.title : 'Something went wrong',
          time: 6000,
          size:"small"
        });
      }
    )
    getDefinitions().then((result) => {
      console.log("Definitions",result);
      setDefinitions(result);
      },
      (error) => {
        toast({
          type: 'error',
          title: 'Error',
          description: error && error.title ? error.title : 'Something went wrong',
          time: 6000,
          size:"small"
        });
      }
    )
    getSolutionDefinitions().then((result) => {
      console.log("Solution Definitions",result);
      setSolutionDefinitions(result);
      },
      (error) => {
        toast({
          type: 'error',
          title: 'Error',
          description: error && error.title ? error.title : 'Something went wrong',
          time: 6000,
          size:"small"
        });
      }
    )
    if(sessionStorage && sessionStorage.getItem('active_scenario')){
      setDataset(JSON.parse(sessionStorage.getItem('active_scenario')));
    }
    if(sessionStorage && sessionStorage.getItem('lang')) i18n.changeLanguage(sessionStorage.getItem('lang'));
    if(sessionStorage && sessionStorage.getItem('default_scenario')) setDefaultScenario(JSON.parse(sessionStorage.getItem('default_scenario')));
  },[]);

  return (
    <Router>
      <div className="app">
        <SemanticToastContainer position="top-center" />
        <NavBar 
          showLogo={true} 
          title={dataset &&  dataset.Scenario && dataset.Scenario.Configuration} 
          loading={isLoading}
          toggleMenu={toggleMenuOpen}
        />
        <Switch>
          <Route path="/results">
            <Results 
              defaultScenario={defaultScenario} 
              def={definitions} 
              solutionDef={solutionDefinitions} 
              data={dataset} 
              loading={isLoading} 
              setData={recalculate} 
              menuOpen={menuOpen}
              exportCalc={()=>{setOpenExport(true)}}/>  
          </Route>
          <Route path="/advanced">
            <Advanced 
              defaultScenario={defaultScenario} 
              def={definitions} 
              menuOpen={menuOpen}
              solutionDef={solutionDefinitions} 
              data={dataset} 
              loading={isLoading} 
              setData={recalculate} 
              errors={errors} 
              exportCalc={()=>{setOpenExport(true)}}/> 
          </Route>
          <Route path="/project">
            <ProjectSetup def={definitions} loading={isLoading} data={dataset.Scenario ? dataset.Scenario : dataset } setData={recalculate} errors={errors}/> 
          </Route>
          <Route path="/setup">
            <Setup 
              def={definitions} 
              data={dataset} 
              setData={recalculate} 
              errors={errors} 
              setDefaultScenario={(e)=>{
                if(sessionStorage){
                  sessionStorage.setItem('default_scenario', JSON.stringify(e))  
                }
                setDefaultScenario(e);
              }}
              scenarioList={scenarios}/> 
          </Route>
          <Route path="/">
            <Landing setData={recalculate} setDefaultScenario={setDefaultScenario}/> 
          </Route>
        </Switch>
        
        <Modal
          onClose={() => setOpenExport(false)}
          onOpen={() => setOpenExport(true)}
          open={openExport}
        >
          <Modal.Header>{t("export.title")}</Modal.Header>
          <Modal.Content>
            {t("export.description")}
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => setOpenExport(false)}>
              {t("export.cancel")}
            </Button>
            <DownloadLink callback={() => setOpenExport(false)} data={{...dataset, key: defaultScenario}} label={t("export.export")} fileName={dataset && dataset.Scenario ? dataset.Scenario.Configuration : "data" }/>
          </Modal.Actions>
        </Modal>
      </div>
    </Router>
  );
}

export default App;
