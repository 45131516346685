import "./index.scss";
import React, {useState, useEffect} from "react";
import { Table, Input } from "semantic-ui-react";


const FormFieldTable = ({head = [],path, data, error, onchange, onblur}) => {
  const [rows, setRows] = useState([]);

  useEffect(()=>{
    let _rows = []
    for(let key in data) {
      if(key.match(path)){
        _rows.push({key: key, name: key.replace(path, ''), value: data[key]});
      }
    }
    setRows(_rows);
  },[data, path]);

  return (
    <Table className="form-field-table" compact="very" unstackable collapsing celled>
      <Table.Header>
        <Table.Row>
          {
            head.map(_title=><Table.HeaderCell key={_title}>{_title}</Table.HeaderCell>)
          }
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {rows.map((row, i) => (
          <Table.Row key={`row-${i}`}>
            <Table.Cell >{row.name}</Table.Cell>
            <Table.Cell >
              <Input 
                error={!!error}
                type="number"
                value={row.value || ""} 
                onBlur={(e)=>{onblur(row.key, parseFloat(e.target.value))}}
                onChange={(e)=>{onchange(row.key, e.target.value)}}
              />  
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default FormFieldTable;
