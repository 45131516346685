import './index.scss';
import { useTranslation } from 'react-i18next';
import { Icon, Loader } from 'semantic-ui-react';
import {useLocation} from "react-router-dom";

const NavBar = ({ title, toggleMenu, className, centered, loading, showLogo = false, logoText, logoLink = '#' })=>{
  const { t } = useTranslation();
  let location = useLocation();

  logoText = logoText || t('appName');

  return (
    <div className={`nav-bar ${className || ''} ${!!centered  ? "nav-bar--centered" : ""}`}>
      <div className="nav-bar__logo">
        {
          location.pathname && 
          (location.pathname === "/results" || location.pathname === "/advanced") && 
          <Icon className="nav-bar__menu" name="bars" onClick={toggleMenu} link/>
        }
        {
          showLogo &&
          <a href={logoLink}>{logoText}</a>
        }
      </div>
      <span className="nav-bar__title">{title}</span>
      <div className="nav-bar__loader">
        <Loader size='small' active={loading} inline/>
      </div>
    </div>
  );
}

export default NavBar;