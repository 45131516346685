import './index.scss';
import { useTranslation } from 'react-i18next';
import { Icon, Confirm, Button } from 'semantic-ui-react';
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

const SideMenu = ({ title, showMenu, selected, active, className, setActive, exportCalc, resetCalc = ()=>{} })=>{
  const { t } = useTranslation();
  const [confirmRestart, setConfirmRestart] = useState(false);
  const history = useHistory();

  return (
    <div className={`side-menu ${className || ''} ${showMenu ? 'open' : ''}`}>
      <div className={`side-menu__item ${selected !== "advanced" ? "active" : ""}`} onClick={()=>{history.push("/results")}}>
        {t("results.title")}
      </div>
      {
        (!selected || selected === "results") && 
        <div className="side-menu__subitems">
          <div onClick={()=>{setActive("KeyValues")}} className={`side-menu__subitems__item ${active === "KeyValues" ? "active" : ""}`}>{t("Navigation.KeyValues")}</div>
          <div onClick={()=>{setActive("CorporateFinance")}} className={`side-menu__subitems__item ${active === "CorporateFinance" ? "active" : ""}`}>{t("Navigation.CorporateFinance")}</div>
          <div onClick={()=>{setActive("UserEconomy")}} className={`side-menu__subitems__item ${active === "UserEconomy" ? "active" : ""}`}>{t("Navigation.UserEconomy")}</div>
          <div onClick={()=>{setActive("Emmissions")}} className={`side-menu__subitems__item ${active === "Emmissions" ? "active" : ""}`}>{t("Navigation.Emmissions")}</div>
        </div>
      }
      <div className={`side-menu__item ${selected === "advanced" ? "active no-border-top" : ""}`} onClick={()=>{history.push("/advanced")}}>
        {t("advanced.title")}
      </div>
      {
        (selected === "advanced") && 
        <div className="side-menu__subitems">
          <div onClick={()=>{setActive("General")}} className={`side-menu__subitems__item ${active === "General" ? "active" : ""}`}>{t("Navigation.General")}</div>
          <div onClick={()=>{setActive("CorporateFinance")}} className={`side-menu__subitems__item ${active === "CorporateFinance" ? "active" : ""}`}>{t("Navigation.CorporateFinance")}</div>
          <div onClick={()=>{setActive("Sensitivity")}} className={`side-menu__subitems__item ${active === "Sensitivity" ? "active" : ""}`}>{t("Navigation.Sensitivity")}</div>
          <div onClick={()=>{setActive("UserEconomy")}} className={`side-menu__subitems__item ${active === "UserEconomy" ? "active" : ""}`}>{t("Navigation.UserEconomy")}</div>
          <div onClick={()=>{setActive("Emmissions")}} className={`side-menu__subitems__item ${active === "Emmissions" ? "active" : ""}`}>{t("Navigation.Emmissions")}</div>
          <div onClick={()=>{setActive("Thermodynamics")}} className={`side-menu__subitems__item ${active === "Thermodynamics" ? "active" : ""}`}>{t("Navigation.Thermodynamics")}</div>
          <div onClick={()=>{setActive("Prices")}} className={`side-menu__subitems__item ${active === "Prices" ? "active" : ""}`}>{t("Navigation.Prices")}</div>
        </div>
      }
      <div className="bottom">
        <div className={`side-menu__item`} onClick={exportCalc} >
          <Icon name='download' />
          {t("Navigation.export")}
        </div>
        
        <div className={`side-menu__item`} onClick={()=>setConfirmRestart(true)} >
          <Icon name='share' /> 
          {t("Navigation.newCalculation")}
        </div>
      </div>
      <Confirm
          open={confirmRestart}
          confirmButton={<Button color="teal" primary={false}>OK</Button>}
          content={t('Navigation.newCalculationPrompt')}
          header={t('Navigation.newCalculation')}
          onCancel={()=>setConfirmRestart(false)}
          onConfirm={()=>{
            if(sessionStorage) sessionStorage.removeItem('active_scenario');
            if(sessionStorage) sessionStorage.removeItem('default_scenario');
            resetCalc();
            history.push("");
          }}
        />
    </div>
  );
}

export default SideMenu;