import "./index.scss";
import React, {useState, useEffect} from "react";
import { Table, Input } from "semantic-ui-react";
import {unflatten} from 'flat';
import { parseDefProp, parseUnit } from '../../services/utils';
import { useTranslation } from 'react-i18next';


const EmmissionFieldTable = ({path="EmissionCoefficients.ConstantCoefficients.", data, error, onchange, onblur, def}) => {
  const [rows, setRows] = useState({});
  const [rowOrder] = useState(["CO2", "CH4", "N2O", "SO2", "NOx", "PM25"]);
  const [cellOrder] = useState(["HouseholdGas", "HouseholdOil", "HouseholdWoodPellets", "HouseholdWood", "PowerPlantWoodChips"]);
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language === "dk" ? "da" : i18n.language;

  useEffect(()=>{
    let _rows = {}
    for(let key in data) {
      if(key.match(path)){
        _rows[key.replace(path, '')] = data[key];
      }
    }
    setRows(unflatten(_rows));
  },[data, path]);

  return rows && Object.keys(rows) && Object.keys(rows).length && (
    <Table className="emission-field-table" compact="very" unstackable collapsing celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell colSpan={4}>{t('advanced.emissionSection.households')}</Table.HeaderCell>
          <Table.HeaderCell>{t('advanced.emissionSection.powerplant')}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          {
            cellOrder.map(_title=><Table.HeaderCell key={_title}>{t(`advanced.emissionSection.${_title}`)}</Table.HeaderCell>)
          }
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          rowOrder.map((cellKey, i) => {
            const rowProps = parseDefProp(def, path+cellOrder[0]+'.'+cellKey);
            return (
              <Table.Row key={`row-${i}`}>
                <Table.Cell>{(rowProps && rowProps.Title) ? rowProps.Title[languageCode] : cellKey} {(rowProps && rowProps.PropertyUnit) ? `(${parseUnit(rowProps.PropertyUnit, i18n.language)})` : ''}</Table.Cell>
                {cellOrder.map((row) => (
                  <Table.Cell key={`row-${i}-${row}`}>
                    <Input 
                      error={!!error}
                      type="number"
                      value={rows[row][cellKey]} 
                      onBlur={(e)=>{onblur(`${path}${row}.${cellKey}`, parseFloat(e.target.value))}}
                      onChange={(e)=>{onchange(`${path}${row}.${cellKey}`, e.target.value)}}
                    />  
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </Table>
  );
}

export default EmmissionFieldTable;
